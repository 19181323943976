import { File, FileTypes, File_MD } from '@helpers/fileHelper';
import { JSONContent } from '@tiptap/core';

export default class emdeeFileMD {
  static create(name: string, content?: JSONContent): File {
    return {
      uuid: crypto.randomUUID(),
      type: FileTypes.MARKDOWN,
      name,
      content: content || {},
    };
  }
}
