import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { useDelayUnmount } from '@core/hooks/useDelayUnmount';
import { selectThemeConfig, setFileList } from '@core/store/appSlice/appSlice';
import { EmdeeSettings } from '@core/store/settingsSlice/settingsSlice';
import { selectOfflineMode } from '@core/store/userSlice/userSlice';
import { initialSetup } from '@helpers/fileHelper';
import { setEmdeeSettings } from '@helpers/settingsHelper';
import { ThemeModeConstants } from '@helpers/themeHelper';
import { createUser } from '@helpers/userHelper';
import NSCButton from '@nsc-ui/Button';
import NSCIconButton from '@nsc-ui/IconButton';
import { useAuth } from '@providers/authProvider';
import { FSUser } from '@storage/firebase/firestore';

import Screen01 from './partials/Screen01';
import Screen02 from './partials/Screen02';
import Screen03 from './partials/Screen03';
import Screen04 from './partials/Screen04';
import Screen05 from './partials/Screen05';

const transitionTime = 300;
const sectionMount = {
  animation: `welcomeInAnimation ${transitionTime}ms ease-in-out`,
};
const sectionUnmount = {
  animation: `welcomeOutAnimation ${transitionTime}ms ease-in-out`,
};

interface WelcomeProps {
  isMounted: boolean;
  onComplete: () => void;
}

const Welcome: React.FC<WelcomeProps> = ({ isMounted, onComplete }) => {
  const CSSBlock = 'welcome';
  const mountedStyle = { animation: 'inAnimation 200ms ease-in-out' };
  const unmountedStyle = { animation: 'outAnimation 200ms ease-in-out' };

  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const isOfflineMode = useAppSelector(selectOfflineMode);

  /**
   *  Conditional rendering + transitions
   */
  const [mounted, setMounted] = useState(1);
  const shouldRender1 = useDelayUnmount(mounted === 1, transitionTime);
  const shouldRender2 = useDelayUnmount(mounted === 2, transitionTime);
  const shouldRender3 = useDelayUnmount(mounted === 3, transitionTime);
  const shouldRender4 = useDelayUnmount(mounted === 4, transitionTime);
  const shouldRender5 = useDelayUnmount(mounted === 5, transitionTime);

  /**
   * Theme config
   */
  const themeConfig = useAppSelector(selectThemeConfig);
  const [hueValues, setHueValues] = useState(themeConfig.hue);
  const [intensityValue, setIntensityValue] = useState(themeConfig.intensity);
  const [grainValue, setGrainValue] = useState(themeConfig.grain);

  const setupUser = async () => {
    setMounted(5);
    createUser({
      dispatch,
      isOfflineMode,
      user,
      theme: {
        hue: hueValues,
        intensity: intensityValue,
        grain: grainValue,
        mode: ThemeModeConstants.SYSTEM,
      },
    }).then(() => {
      initialSetup(isOfflineMode, user).then(index => {
        if (index) dispatch(setFileList(index));
        setTimeout(() => onComplete(), 2000);
      });
    });
  };

  const next = () => {
    if (mounted < 4) setMounted(Math.min(mounted + 1, 4));
    else setupUser();
  };
  const previous = () => setMounted(Math.max(mounted - 1, 1));

  return (
    <div
      className={`${CSSBlock}__wrapper`}
      style={isMounted ? mountedStyle : unmountedStyle}>
      <div
        className={`${CSSBlock}`}
        style={isMounted ? mountedStyle : unmountedStyle}>
        <div className={`${CSSBlock}__container`}>
          <div className={`${CSSBlock}__progress`}>
            <div
              className={`${CSSBlock}__progress-bar`}
              style={{
                flexBasis: `${Math.min(100, (mounted / 4) * 100)}%`,
              }}></div>
          </div>
          <div className={`${CSSBlock}__content`}>
            {shouldRender1 && (
              <Screen01
                isMounted={mounted === 1}
                mountedStyle={sectionMount}
                unmountedStyle={sectionUnmount}
              />
            )}
            {shouldRender2 && (
              <Screen02
                isMounted={mounted === 2}
                mountedStyle={sectionMount}
                unmountedStyle={sectionUnmount}
              />
            )}
            {shouldRender3 && (
              <Screen03
                isMounted={mounted === 3}
                mountedStyle={sectionMount}
                unmountedStyle={sectionUnmount}
                hueValues={hueValues}
                setHueValues={setHueValues}
                intensityValue={intensityValue}
                setIntensityValue={setIntensityValue}
                grainValue={grainValue}
                setGrainValue={setGrainValue}
              />
            )}
            {shouldRender4 && (
              <Screen04
                isMounted={mounted === 4}
                mountedStyle={sectionMount}
                unmountedStyle={sectionUnmount}
              />
            )}
            {shouldRender5 && (
              <Screen05
                isMounted={mounted === 5}
                mountedStyle={sectionMount}
                unmountedStyle={sectionUnmount}
              />
            )}
          </div>
          <div className={`${CSSBlock}__buttons`}>
            <NSCIconButton
              faIcon="fa-chevron-left"
              onClick={() => previous()}
              disabled={mounted === 1 || mounted === 5}
            />
            <NSCButton
              text={mounted === 4 ? `Let's begin!` : 'Proceed'}
              faIcon="fa-chevron-right"
              onClick={() => next()}
              disabled={mounted === 5}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
