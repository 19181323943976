import React from 'react';

import LogoDark from '@assets/logo-black.svg';
import LogoLight from '@assets/logo-white.svg';
import AppMenu from '@components/AppMenu';

import pkg from '../../../../package.json';
import SidebarUser from './SidebarUser';

const SidebarHeading: React.FC = () => {
  const CSSBlock = 'sidebar-heading';

  return (
    <>
      <div className={`${CSSBlock}`}>
        <span className="emdee-logo">
          <img className="emdee-logo__image--light" src={LogoLight} />
          <img className="emdee-logo__image--dark" src={LogoDark} />
          <span className="emdee-logo__version">v{pkg.version} alpha</span>
        </span>
        <AppMenu />
      </div>
      <SidebarUser />
    </>
  );
};

export default SidebarHeading;
