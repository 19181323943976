import { User } from 'firebase/auth';
import {
  DocumentReference,
  arrayUnion,
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';

import { FileMetadata, File_MDX, File_MDX_Tab } from '@helpers/fileHelper';
import { FSCollections } from '@providers/firestoreProvider';
import { JSONContent } from '@tiptap/core';

export default class FSFileMDX {
  /**
   *
   * @param file
   * @param tab
   * @param content
   * @returns
   */
  static async updateContent(
    file: FileMetadata,
    tab: number,
    content: JSONContent
  ): Promise<File_MDX | undefined> {
    if (tab < 0) return;

    const fileRef = doc(
      FSCollections.Files,
      file.uuid
    ) as DocumentReference<File_MDX>;
    const docSnap = await getDoc(fileRef);

    if (docSnap.exists()) {
      const existingFile = docSnap.data();
      const tabs = existingFile.tabs;
      tabs[tab].content = content;

      const updatedFile = { ...existingFile, tabs };
      await updateDoc(fileRef, { tabs: tabs });
      return updatedFile;
    } else {
      console.error('FSFileMDX', 'updateContent', 'File not found!');
    }
  }

  static async createTab(
    file: FileMetadata,
    tabName: string
  ): Promise<File_MDX | undefined> {
    const fileRef = doc(
      FSCollections.Files,
      file.uuid
    ) as DocumentReference<File_MDX>;
    const docSnap = await getDoc(fileRef);
    if (docSnap.exists()) {
      const existingFile = docSnap.data();
      const tabs = existingFile.tabs;
      await updateDoc(fileRef, {
        tabs: arrayUnion({ name: tabName }),
        activeTab: tabs.length,
      });
      return docSnap.data() as File_MDX;
    } else {
      console.error('FSIndex', 'moveFile', `Couldn't find index.`);
    }
  }

  static async renameTab(
    file: File_MDX,
    tab: number,
    name: string
  ): Promise<File_MDX | undefined> {
    const fileRef = doc(
      FSCollections.Files,
      file.uuid
    ) as DocumentReference<File_MDX>;
    const docSnap = await getDoc(fileRef);
    if (docSnap.exists()) {
      const existingFile = docSnap.data() as File_MDX;
      const tabs = existingFile.tabs;
      tabs[tab].name = name;
      await updateDoc(fileRef, { tabs: tabs });
      return docSnap.data() as File_MDX;
    } else {
      console.error('FSIndex', 'moveFile', `Couldn't find index.`);
    }
  }

  static async deleteTab(
    file: File_MDX,
    tab: number
  ): Promise<File_MDX | undefined> {
    const fileRef = doc(
      FSCollections.Files,
      file.uuid
    ) as DocumentReference<File_MDX>;
    const docSnap = await getDoc(fileRef);
    if (docSnap.exists()) {
      const existingFile = docSnap.data() as File_MDX;
      // Change active tab to last tab
      const tabs = existingFile.tabs;
      tabs.splice(tab, 1);
      await updateDoc(fileRef, { tabs: tabs, activeTab: tabs.length - 1 });
      return docSnap.data() as File_MDX;
    } else {
      console.error('FSIndex', 'moveFile', `Couldn't find index.`);
    }
  }

  static async setActiveTab(
    file: File_MDX,
    tab: number
  ): Promise<File_MDX | undefined> {
    const fileRef = doc(
      FSCollections.Files,
      file.uuid
    ) as DocumentReference<File_MDX>;
    const docSnap = await getDoc(fileRef);
    if (docSnap.exists()) {
      await updateDoc(fileRef, { activeTab: tab });
      return docSnap.data() as File_MDX;
    } else {
      console.error('FSIndex', 'moveFile', `Couldn't find index.`);
    }
  }
}
