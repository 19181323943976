import React, { HTMLProps, PropsWithChildren } from 'react';

import NSCCardArea from './CardArea';

interface NSCCardProps extends PropsWithChildren, HTMLProps<HTMLDivElement> {
  buttons: JSX.Element[];
}

const NSCCardButtonArea: React.FC<NSCCardProps> = ({ buttons, ...props }) => {
  const CSSBlock = 'nsc-card';

  return (
    <NSCCardArea
      {...props}
      className={`${CSSBlock}__area-buttons ${
        props.className ? props.className : ``
      }`}>
      <div className={`${CSSBlock}__action-buttons`}>{buttons}</div>
    </NSCCardArea>
  );
};

export default NSCCardButtonArea;
