import { User } from 'firebase/auth';

import initialContent from '@components/page/partials/initialContent';
import { setActiveFile, setFileList } from '@core/store/appSlice/appSlice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { emdeeFilesIndex, isEmdeeFilesIndex } from '@storage/emdee/_common';
import EmdeeStorage, { EmdeeStorageType } from '@storage/emdee/emdeeStorage';
import { JSONContent } from '@tiptap/core';

/**
 * File type definitions
 */
export enum FileTypes {
  MARKDOWN = 'md',
  COLLECTION = 'mdx',
}
export interface FileMetadata {
  uuid: string;
  type: FileTypes;
  name: string;
}

export interface File_MD extends FileMetadata {
  content?: JSONContent;
}
export interface File_MDX_Tab {
  name: string;
  content?: JSONContent;
}
export interface File_MDX extends FileMetadata {
  tabs: File_MDX_Tab[];
  activeTab: number;
}
export type File = File_MDX | File_MD;

export function isFile(T: any): T is File {
  return (T as File).type !== undefined && (T as File).name !== undefined;
}

export function isFileArray(T: any): T is File {
  return Array.isArray(T) && T.length > 0 && isFile(T[0]);
}

/**
 * Folder type definitions
 */
export type Folder = {
  uuid: string;
  name: string;
  items: (FileMetadata | Folder)[];
};

export function isFolder(T: any): T is Folder {
  return (T as Folder).name !== undefined && (T as Folder).items !== undefined;
}

/**
 *
 * @param file
 */
export const getFileMetadata = (file: File | FileMetadata): FileMetadata => ({
  uuid: file.uuid,
  name: file.name,
  type: file.type,
});

/**
 *
 * @param storage
 * @param metadata
 */
export const getFileFromFileMetadata = async (
  storage: EmdeeStorageType,
  metadata: FileMetadata
): Promise<File | undefined> => {
  return await EmdeeStorage.loadFile(storage, metadata.uuid);
};

/**
 *
 * @param dispatch
 * @param activeTab
 */
export const refreshFileList = async (
  dispatch: ThunkDispatch<any, undefined, any>,
  storage: EmdeeStorageType,
  user?: User | null,
  activeFile?: FileMetadata
) => {
  const index = await EmdeeStorage.getIndex(storage, user);
  if (index && isEmdeeFilesIndex(index)) {
    // Update file list
    dispatch(setFileList(index));
    // Update active tab
    if (activeFile) {
      let file = index.content.find(file => file.uuid === activeFile.uuid);
      // If activeFile doesn't exist anymore, select first other file.
      if (!file) file = index.content.find(file => isFile(file));
      dispatch(setActiveFile(file as FileMetadata));
    }
  }
};

/**
 * Get File List
 * @param isOfflineMode
 * @param user
 */
export const getFileIndex = async (
  isOfflineMode: boolean,
  user?: User | null
): Promise<emdeeFilesIndex | undefined> => {
  console.log('getFileIndex', isOfflineMode);
  const index = await EmdeeStorage.getIndex(
    isOfflineMode ? EmdeeStorageType.LOCAL : EmdeeStorageType.FIRESTORE,
    user
  );
  console.log('getFileIndex', index);

  return index;
};

/**
 * Get File List
 * @param isOfflineMode
 * @param user
 */
export const initialSetup = async (
  isOfflineMode: boolean,
  user?: User | null
): Promise<emdeeFilesIndex | undefined> => {
  console.log('initialSetup');

  await EmdeeStorage.createFile(
    isOfflineMode ? EmdeeStorageType.LOCAL : EmdeeStorageType.FIRESTORE,
    {
      name: 'Welcome',
      type: FileTypes.MARKDOWN,
      content: initialContent,
    },
    user
  );

  await EmdeeStorage.createFile(
    isOfflineMode ? EmdeeStorageType.LOCAL : EmdeeStorageType.FIRESTORE,
    {
      name: 'To-Do List',
      type: FileTypes.COLLECTION,
      tabName: 'Primary Goals',
    },
    user
  );

  return await EmdeeStorage.getIndex(
    isOfflineMode ? EmdeeStorageType.LOCAL : EmdeeStorageType.FIRESTORE,
    user
  );
};
