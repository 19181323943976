import { EmdeeSettings } from '@core/store/settingsSlice/settingsSlice';
import { File_MD, File_MDX } from '@helpers/fileHelper';
import { ThemeConfig } from '@helpers/themeHelper';

export enum FSCollectionName {
  USERS = 'users',
  INDEX = 'index',
  FILES = 'files',
}
export interface FSCollection_User {
  theme: ThemeConfig;
  lastSeen: Date;
  settings: EmdeeSettings;
}
export type FSCollection_File = File_MD | File_MDX;
