import React, { useEffect, useRef, useState } from 'react';

import NSCButton, { NSCButtonProps } from '@nsc-ui/Button';
import NSCCard from '@nsc-ui/Card/Card';
import NSCCardArea from '@nsc-ui/Card/CardArea';
import NSCCardButtonArea from '@nsc-ui/Card/CardButtonArea';
import NSCModalCore, { NSCModalCoreRef } from '@nsc-ui/ModalCore';

interface ModalInputProps {
  placeholder?: string;
  initialValue?: string;
  onClose: () => void;
  onConfirm: (value: string) => void;
}

const ModalInput: React.FC<ModalInputProps> = ({
  placeholder,
  initialValue,
  onClose,
  onConfirm,
}) => {
  const CSSBlock = 'modal-input';
  const ref = useRef<HTMLInputElement>(null);
  const modalRef = useRef<NSCModalCoreRef>(null);
  const [isOpen, setIsOpen] = useState(true);

  // This ref workaround is for the document event listener
  const [inputValue, _setInputValue] = useState(initialValue || '');
  const inputValueRef = React.useRef(inputValue);
  const setInputValue = (value: string) => {
    inputValueRef.current = value;
    _setInputValue(value);
  };

  const gracefulExit = (postExit: () => void) => {
    modalRef.current ? modalRef.current.gracefulClose(postExit) : postExit();
  };

  const buttons = [
    <NSCButton
      key={0}
      text="Cancel"
      outlined
      onClick={() => gracefulExit(onClose)}
    />,
    <NSCButton
      key={1}
      text="Confirm"
      disabled={!inputValue || inputValue.replaceAll(' ', '').length === 0}
      onClick={() => gracefulExit(() => onConfirm(inputValue))}
    />,
  ];

  useEffect(() => {
    const onKeyDown = (e: globalThis.KeyboardEvent) => {
      if (
        e.key === 'Enter' &&
        inputValueRef.current &&
        inputValueRef.current.replaceAll(' ', '').length > 0
      )
        gracefulExit(() => onConfirm(inputValueRef.current));
    };
    document.addEventListener('keydown', onKeyDown);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  return (
    <NSCModalCore
      ref={modalRef}
      isOpen={isOpen}
      onExited={() => {
        setIsOpen(false);
        onClose();
      }}
      allowBackdropClose={false}>
      <NSCCard className={`${CSSBlock}`}>
        <NSCCardArea isDark>
          <input
            ref={ref}
            className={`${CSSBlock}__input`}
            type="text"
            autoFocus
            spellCheck={false}
            value={inputValue}
            placeholder={placeholder}
            onChange={e => setInputValue(e.currentTarget.value)}
          />
        </NSCCardArea>
        <NSCCardButtonArea buttons={buttons} />
      </NSCCard>
    </NSCModalCore>
  );
};

export default ModalInput;
