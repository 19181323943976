import React from 'react';

import { EmdeeSettings } from '@core/store/settingsSlice/settingsSlice';
import NSCToggle from '@nsc-ui/Toggle';

interface SectionEditorProps {
  settings: EmdeeSettings;
  setSettings: React.Dispatch<React.SetStateAction<EmdeeSettings>>;
}

const SectionEditor: React.FC<SectionEditorProps> = ({
  settings,
  setSettings,
}) => {
  const CSSBlock = 'app-settings';

  const onToggleToolbar = (state: boolean) =>
    setSettings({
      ...settings,
      editor: { ...settings.editor, showToolbar: state },
    });

  const onToggleHints = (state: boolean) =>
    setSettings({
      ...settings,
      editor: { ...settings.editor, showMarkdownHints: state },
    });

  return (
    <>
      <div className={`${CSSBlock}__section`}>
        <div className={`${CSSBlock}__section-heading`}>Page viewing mode</div>
        <div className={`${CSSBlock}__section-description`}>
          Use this area to define your own styling for emdee, including custom
          colours, font sizing, margins and more.
        </div>
        <div className={`${CSSBlock}__section-content`}>
          <NSCToggle
            text="Enable sticky toolbar"
            onToggle={onToggleToolbar}
            isActive={settings.editor.showToolbar}
          />
          <NSCToggle
            text="Show markdown hints"
            onToggle={onToggleHints}
            isActive={settings.editor.showMarkdownHints}
          />

          <NSCToggle
            text="Enable condensed mode"
            onToggle={() => {}}
            disabled
          />
        </div>
      </div>
    </>
  );
};

export default SectionEditor;
