import React from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectActiveFile } from '@core/store/appSlice/appSlice';

const Header_MD: React.FC = () => {
  const CSSBlock = 'header-md';
  const activeFile = useAppSelector(selectActiveFile);

  return (
    <div className={CSSBlock}>
      <div className={`${CSSBlock}__filename`}>{activeFile?.name || ''}</div>
    </div>
  );
};

export default Header_MD;
