import { File, FileMetadata, Folder } from '@helpers/fileHelper';
import { ThemeConfig } from '@helpers/themeHelper';
import { initialDocContent } from '@helpers/userHelper';
import {
  emdeeFilesIndex,
  recursiveDeleteFileInIndex,
  recursiveRenameFileInIndex,
} from '@storage/emdee/_common';
import { FSCollection_User } from '@storage/firebase/_types';

import LSIndex from './_index';
import { LSKeys } from './localStorage';

export default class LSUser {
  /**
   *
   * @param user
   * @returns
   */
  static createUserDoc = async (
    content?: FSCollection_User | ThemeConfig
  ): Promise<FSCollection_User> => {
    const data = initialDocContent(content);

    // try {
    //   data.
    //   localStorage.setItem(LSKeys.INDEX, JSON.stringify(index));
    // } catch (e: any) {
    //   console.error('LSIndex', 'set', e);
    //   return undefined;
    // }

    return data;
  };

  /**
   *
   */
  static getIndex(): emdeeFilesIndex | undefined {
    try {
      const index = localStorage.getItem(LSKeys.INDEX);
      return index ? (JSON.parse(index) as emdeeFilesIndex) : undefined;
    } catch (e: any) {
      console.error('LSIndex', 'getIndex', e);
      return undefined;
    }
  }

  static set(index: emdeeFilesIndex) {
    try {
      localStorage.setItem(LSKeys.INDEX, JSON.stringify(index));
    } catch (e: any) {
      console.error('LSIndex', 'set', e);
      return undefined;
    }
  }

  static fileRename(uuid: string, newName: string) {
    const currentFiles = localStorage.getItem(LSKeys.INDEX);
    if (currentFiles) {
      const filesIndex = JSON.parse(currentFiles) as emdeeFilesIndex;
      filesIndex.content = recursiveRenameFileInIndex(
        filesIndex.content,
        uuid,
        newName
      );
      LSIndex.set(filesIndex);
    }
  }

  /**
   *
   */
  static removeItem(file: File | FileMetadata | Folder) {
    try {
      const index = LSIndex.getIndex();
      if (index) {
        index.content = recursiveDeleteFileInIndex(index.content, file.uuid);
        // Update index
        LSIndex.set(index);
      }
    } catch (e) {
      console.error('LSIndex', 'removeItem', e);
    }
  }
}
