import React, { useRef, useState } from 'react';

import ModalInput from '@components/modals/ModalInput';
import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { selectOfflineMode } from '@core/store/userSlice/userSlice';
import { createRequest } from '@helpers/appHelper';
import {
  FileMetadata,
  FileTypes,
  Folder,
  refreshFileList,
} from '@helpers/fileHelper';
import NSCIconButton, { IconButtonSize } from '@nsc-ui/IconButton';
import Menu from '@nsc-ui/Menu/Menu';
import { MenuContent } from '@nsc-ui/Menu/MenuTypes';
import { useAuth } from '@providers/authProvider';
import EmdeeStorage, { EmdeeStorageType } from '@storage/emdee/emdeeStorage';

import File from './File';

const menuItems: MenuContent = {
  sections: [
    {
      items: [
        {
          id: 'new-file',
          faIcon: 'fa-solid fa-file',
          text: 'New File',
        },
        {
          id: 'new-collection',
          faIcon: 'fa-file-code',
          text: 'New File Collection',
        },
      ],
    },
  ],
};

enum MODALS {
  NEW_FILE = 'new-file',
  NEW_FILE_COLLECTION = 'new-file-collection',
  NEW_FILE_COLLECTION_TAB = 'new-file-collection-tab',
}

interface FolderFCProps {
  folder: Folder;
}

const FolderFC: React.FC<FolderFCProps> = ({ folder }) => {
  const CSSBlock = 'folder';
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const isOfflineMode = useAppSelector(selectOfflineMode);
  const storageMode = isOfflineMode
    ? EmdeeStorageType.LOCAL
    : EmdeeStorageType.FIRESTORE;

  const folderItemsRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [folderItemsHeight, setFolderItemsHeight] = useState(0);
  const [activeModal, setActiveModal] = useState<undefined | MODALS>(undefined);
  const [collectionName, setCollectionName] = useState<string>();

  const onMenuClick = (id: string) => {
    switch (id) {
      case 'new-file':
        setActiveModal(MODALS.NEW_FILE);
        break;
      case 'new-collection':
        setActiveModal(MODALS.NEW_FILE_COLLECTION);
        break;
    }
  };

  const onCreateFile = (name: string) => {
    if (name.replaceAll(' ', '').length > 0) {
      createRequest(
        EmdeeStorage.createFile(
          storageMode,
          { name, folderUUID: folder.uuid, type: FileTypes.MARKDOWN },
          user
        )
      ).then(newFile => {
        if (newFile) postCreate(newFile);
      });
    }
  };

  const onStartFileCollection = (name: string) => {
    if (name.replaceAll(' ', '').length > 0) {
      setCollectionName(name);
      setActiveModal(MODALS.NEW_FILE_COLLECTION_TAB);
    }
  };

  const onCreateFileCollection = (tabName: string) => {
    if (collectionName && tabName.replaceAll(' ', '').length > 0) {
      createRequest(
        EmdeeStorage.createFile(
          storageMode,
          {
            name: collectionName,
            tabName,
            folderUUID: folder.uuid,
            type: FileTypes.COLLECTION,
          },
          user
        )
      ).then(newFile => {
        if (newFile) postCreate(newFile);
      });
    }
  };

  const postCreate = async (activeFile?: FileMetadata) => {
    await createRequest(
      refreshFileList(dispatch, storageMode, user, activeFile)
    );
    setActiveModal(undefined);
  };

  const onClickTrigger = () => {
    if (isOpen) {
      setFolderItemsHeight(0);
      setIsOpen(false);
    } else {
      if (folderItemsRef?.current) {
        let itemHeight = 0;
        Array.from(folderItemsRef.current.children).forEach(
          child => (itemHeight += child.clientHeight + 4)
        );
        setFolderItemsHeight(itemHeight + 6);
      }
      setIsOpen(true);
    }
  };

  return (
    <div className={`${CSSBlock} ${isOpen ? `${CSSBlock}--expanded` : ''}`}>
      <div className={`${CSSBlock}__buttons-wrapper`}>
        <button className={`${CSSBlock}__button`} onClick={onClickTrigger}>
          <span className={`${CSSBlock}__button-icon`}>
            <i className={`fa-solid fa-angle-right`}></i>
          </span>
          {folder.name}
        </button>
        <Menu
          triggerEl={
            <NSCIconButton
              faIcon="fa-ellipsis-vertical"
              size={IconButtonSize.MINI}
            />
          }
          onClick={onMenuClick}
          content={menuItems}
        />
      </div>
      <div
        className={`${CSSBlock}__folder-items`}
        ref={folderItemsRef}
        style={{ maxHeight: `${folderItemsHeight}px` }}>
        {folder.items.length > 0 ? (
          [...folder.items]
            .sort((a, b) =>
              a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1
            )
            .map((item, i) => (
              <File
                key={i}
                metadata={item as FileMetadata}
                tabIndex={isOpen ? undefined : -1}
              />
            ))
        ) : (
          <div className={`${CSSBlock}__empty`}>Empty</div>
        )}
      </div>

      {activeModal === MODALS.NEW_FILE && (
        <ModalInput
          placeholder="Give your file a name"
          onClose={() => setActiveModal(undefined)}
          onConfirm={onCreateFile}
        />
      )}
      {activeModal === MODALS.NEW_FILE_COLLECTION && (
        <ModalInput
          placeholder="Give your file a name"
          onClose={() => setActiveModal(undefined)}
          onConfirm={onStartFileCollection}
        />
      )}
      {activeModal === MODALS.NEW_FILE_COLLECTION_TAB && (
        <ModalInput
          placeholder="Name your first tab"
          onClose={() => setActiveModal(undefined)}
          onConfirm={onCreateFileCollection}
        />
      )}
    </div>
  );
};

export default FolderFC;
