import {
  popNetworkRequestUUID,
  pushNetworkRequestUUID,
} from '@core/store/appSlice/appSlice';
import { store } from '@core/store/store';

export const createRequest = (request: Promise<any>) => {
  const requestUUID = crypto.randomUUID();
  store.dispatch(pushNetworkRequestUUID(requestUUID));
  return request.then(res => {
    // Delay by 100ms to allow chained requests to keep loading indicator active
    setTimeout(() => {
      store.dispatch(popNetworkRequestUUID(requestUUID));
    }, 100);
    return res;
  });
};
