import React from 'react';

interface Screen01Props {
  isMounted: boolean;
  mountedStyle: React.CSSProperties;
  unmountedStyle: React.CSSProperties;
}
const Screen01: React.FC<Screen01Props> = ({
  isMounted,
  mountedStyle,
  unmountedStyle,
}) => {
  const CSSBlock = 'welcome-section';

  return (
    <div
      className={`${CSSBlock}`}
      style={isMounted ? mountedStyle : unmountedStyle}>
      <div className={`${CSSBlock}__title`}>
        Welcome to e<b>md</b>ee.io
      </div>
      <div className={`${CSSBlock}__description`}>
        You seem to be new here.
        <br />
        Let's help you get started.
      </div>
    </div>
  );
};

export default Screen01;
