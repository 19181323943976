import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Editor } from '@tiptap/react';

import { RootState } from '../store';

interface PageState {
  editor: Editor | null;
}

const initialState: PageState = {
  editor: null,
};

export const pageSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    /**
     *
     */
    setEditor: (state, action: PayloadAction<Editor | null>) => {
      state.editor = action.payload;
    },
  },
});

export const { setEditor } = pageSlice.actions;

export const selectEditor = (state: RootState) => state.page.editor;

export default pageSlice.reducer;
