import React from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectFileList } from '@core/store/appSlice/appSlice';
import { isFile, isFolder } from '@helpers/fileHelper';
import { emdeeFilesIndex } from '@storage/emdee/_common';

import File from './File';
import Folder from './Folder';

const sortIndexContent = (index: emdeeFilesIndex): emdeeFilesIndex => ({
  ...index,
  content: [...index.content].sort((a, b) => {
    return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
  }),
});

const renderItems = (index: emdeeFilesIndex) =>
  sortIndexContent(index).content.map((item, i) =>
    isFolder(item) ? (
      <Folder key={i} folder={item} />
    ) : isFile(item) ? (
      <File key={i} metadata={item} />
    ) : (
      <></>
    )
  );

const FileBrowserList: React.FC = () => {
  const CSSBlock = 'section';
  const fileList = useAppSelector(selectFileList);
  return <div className={`${CSSBlock}__list`}>{renderItems(fileList)}</div>;
};

export default FileBrowserList;
