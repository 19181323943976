import React, { useState } from 'react';

import NSCItemSwitch from './ItemSwitch';

interface NSCButtonSwitchProps {
  buttons: string[];
  initialIndex?: number;
  onClick: (i: number) => void;
}

const NSCButtonSwitch: React.FC<NSCButtonSwitchProps> = ({
  buttons,
  initialIndex = 0,
  onClick,
}) => {
  const CSSBlock = 'nsc-button-switch';
  const [active, setActive] = useState(initialIndex);

  const onItemClick = (i: number) => {
    setActive(i);
    onClick(i);
  };

  const items = buttons.map((button, i) => (
    <button
      className={`${CSSBlock}__item ${
        active === i ? `${CSSBlock}__item--active` : ''
      }`}
      key={i}
      onClick={() => onItemClick(i)}>
      <span className={`${CSSBlock}__item-text`}>{button}</span>
    </button>
  ));

  return (
    <div className={`${CSSBlock}`}>
      <NSCItemSwitch items={items} onClick={onItemClick} />
    </div>
  );
};

export default NSCButtonSwitch;
