import {
  File,
  FileMetadata,
  FileTypes,
  Folder,
  getFileMetadata,
  isFolder,
} from '@helpers/fileHelper';
import emdeeFileMDX from '@storage/emdee/_fileMDX';
import { JSONContent } from '@tiptap/core';

import {
  emdeeFilesIndex,
  getFileStorageKey,
  recursiveDeleteFileInIndex,
} from '../emdee/_common';
import emdeeFileMD from '../emdee/_fileMD';
import LSIndex from './_index';
import { LSKeys } from './localStorage';

/**
 *
 */
export default class LSFiles {
  /**
   *
   */
  static create({
    name,
    type,
    tabName,
    content,
  }: {
    name: string;
    type: FileTypes;
    tabName?: string;
    content?: JSONContent;
  }): File {
    return type === FileTypes.COLLECTION
      ? emdeeFileMDX.create(name, tabName)
      : emdeeFileMD.create(name, content);
  }

  /**
   *
   */
  static load(uuid: string): File | undefined {
    try {
      const file = localStorage.getItem(getFileStorageKey(uuid));
      return file ? (JSON.parse(file) as File) : undefined;
    } catch (e: any) {
      console.error('LSFiles', 'getIndex', e);
      return undefined;
    }
  }

  /**
   *
   */
  static delete(file: File) {
    try {
      // Delete from index.
      // Delete from storage.
      LSIndex.removeItem(file);
      localStorage.removeItem(getFileStorageKey(file.uuid));
      // Update store
    } catch (e) {
      console.error('LSFiles', 'delete', e);
    }
  }

  /**
   * Saves a new file to root
   */
  static save(file: File, folderUUID?: string) {
    // Update to include new file uuid in list (in folder too)
    // Save new file with prefix
    try {
      const currentFiles = localStorage.getItem(LSKeys.INDEX);
      if (currentFiles) {
        const filesIndex = JSON.parse(currentFiles) as emdeeFilesIndex;

        // Update index to include new file.
        // If creating in root, or in a folder.
        if (folderUUID) {
          const folderIndex = filesIndex.content.findIndex(
            item => isFolder(item) && item.uuid === folderUUID
          );
          if (folderIndex > -1) {
            (filesIndex.content[folderIndex] as Folder).items.push(
              getFileMetadata(file)
            );
          } else {
            console.error(
              'LSFiles',
              'save',
              `Couldn't find folder ${folderUUID}`
            );
          }
        } else {
          filesIndex.content = [...filesIndex.content, getFileMetadata(file)];
        }
        localStorage.setItem(LSKeys.INDEX, JSON.stringify(filesIndex));

        // No files currently exist.
      } else {
        const filesIndex = { content: [getFileMetadata(file)] };
        localStorage.setItem(LSKeys.INDEX, JSON.stringify(filesIndex));
      }

      // Save file itself
      localStorage.setItem(getFileStorageKey(file.uuid), JSON.stringify(file));
    } catch (e: any) {
      console.log(e);
    }
  }

  /**
   *
   */
  static rename(file: File, newName: string) {
    try {
      // update file list with new name only
      localStorage.setItem(
        getFileStorageKey(file.uuid),
        JSON.stringify({ ...file, name: newName })
      );

      // update index
      LSIndex.fileRename(file.uuid, newName);
    } catch (e) {
      console.error('LSFiles', 'rename', e);
    }
  }

  /**
   * Change the file's location.
   */
  static moveToFolder(file: FileMetadata | File, folder: Folder) {
    file = getFileMetadata(file);
    try {
      const index = LSIndex.getIndex();
      if (index) {
        // Remove from current place
        index.content = recursiveDeleteFileInIndex(index.content, file.uuid);

        /**
         * Add to target directory.
         * If moving to the root dir or else it's a folder.
         */
        if (folder.uuid === '/') {
          index.content.push(file);
        } else {
          let fileDirectory = index.content.find(
            item => item.uuid === folder.uuid && isFolder(item)
          ) as Folder | undefined;
          // If folder isn't found in index, default to the folder passed in.
          if (!fileDirectory) fileDirectory = folder;
          fileDirectory.items.push(file);
        }

        LSIndex.set(index);
      }
    } catch (e) {}
  }
}
