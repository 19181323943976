import React, { useEffect, useRef, useState } from 'react';

import NSCButton from '@nsc-ui/Button';
import NSCButtonSwitch from '@nsc-ui/ButtonSwitch';
import NSCCard from '@nsc-ui/Card/Card';
import NSCCardArea from '@nsc-ui/Card/CardArea';
import NSCCardButtonArea from '@nsc-ui/Card/CardButtonArea';
import NSCCardTitleArea from '@nsc-ui/Card/CardTitleArea';
import NSCModalCore, { NSCModalCoreRef } from '@nsc-ui/ModalCore';

const CSSBlock = 'about-emdee';

interface AboutEmdeeProps {
  open: boolean;
  onClose: () => void;
}

const AboutEmdee: React.FC<AboutEmdeeProps> = ({ open, onClose }) => {
  const modalRef = useRef<NSCModalCoreRef>(null);
  const [isOpen, setIsOpen] = useState(open);

  const sectionButtons = ['About', 'Credits'];
  const modalButtons = [
    <NSCButton
      text="Close"
      onClick={() =>
        modalRef.current ? modalRef.current.gracefulClose(onClose) : onClose()
      }
    />,
  ];

  useEffect(() => setIsOpen(open), [open]);

  return (
    <NSCModalCore
      ref={modalRef}
      isOpen={isOpen}
      onExited={() => {
        setIsOpen(false);
        onClose();
      }}>
      <NSCCard className={CSSBlock}>
        <NSCCardTitleArea
          titleText={
            <>
              About e<strong>md</strong>ee.io
            </>
          }>
          {/* <NSCButtonSwitch buttons={sectionButtons} onClick={() => {}} /> */}
        </NSCCardTitleArea>
        <NSCCardArea isDark>
          <div
            style={{
              textAlign: 'center',
              lineHeight: '20rem',
            }}>
            Information coming soon.
          </div>
        </NSCCardArea>
        <NSCCardButtonArea buttons={modalButtons}></NSCCardButtonArea>
      </NSCCard>
    </NSCModalCore>
  );
};

export default AboutEmdee;
