import React from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectEditor } from '@core/store/pageSlice/pageSlice';

import ToolbarButton from './partials/_ToolbarButton';

const Toolbar: React.FC = () => {
  const CSSBlock = 'toolbar';
  const editor = useAppSelector(selectEditor);

  const actionButtons = [
    {
      icon: 'ri-arrow-go-back-line',
      onClick: () => editor!.chain().focus().undo().run(),
    },
    {
      icon: 'ri-arrow-go-forward-line',
      onClick: () => editor!.chain().focus().redo().run(),
    },
  ];
  const headingButtons = [
    {
      icon: 'ri-paragraph',
      onClick: () => editor!.chain().focus().setParagraph().run(),
    },
    {
      icon: 'ri-h-1',
      onClick: () => editor!.chain().focus().toggleHeading({ level: 1 }).run(),
    },
    {
      icon: 'ri-h-2',
      onClick: () => editor!.chain().focus().toggleHeading({ level: 2 }).run(),
    },
    {
      icon: 'ri-h-3',
      onClick: () => editor!.chain().focus().toggleHeading({ level: 3 }).run(),
    },
    {
      icon: 'ri-h-4',
      onClick: () => editor!.chain().focus().toggleHeading({ level: 4 }).run(),
    },
    {
      icon: 'ri-h-5',
      onClick: () => editor!.chain().focus().toggleHeading({ level: 5 }).run(),
    },
    {
      icon: 'ri-h-6',
      onClick: () => editor!.chain().focus().toggleHeading({ level: 6 }).run(),
    },
  ];
  const alignButtons = [
    {
      icon: 'ri-align-left',
      onClick: () => editor!.chain().focus().setTextAlign('left').run(),
    },
    {
      icon: 'ri-align-center',
      onClick: () => editor!.chain().focus().setTextAlign('center').run(),
    },
    {
      icon: 'ri-align-right',
      onClick: () => editor!.chain().focus().setTextAlign('right').run(),
    },
    {
      icon: 'ri-align-justify',
      onClick: () => editor!.chain().focus().setTextAlign('justify').run(),
    },
  ];
  const stylesButtons = [
    {
      icon: 'ri-bold',
      onClick: () => editor!.chain().focus().toggleBold().run(),
    },
    {
      icon: 'ri-italic',
      onClick: () => editor!.chain().focus().toggleItalic().run(),
    },
    {
      icon: 'ri-underline',
      onClick: () => editor!.chain().focus().toggleUnderline().run(),
    },
    {
      icon: 'ri-strikethrough',
      onClick: () => editor!.chain().focus().toggleStrike().run(),
    },
    {
      icon: 'ri-subscript',
      onClick: () => editor!.chain().focus().toggleSubscript().run(),
    },
    {
      icon: 'ri-superscript',
      onClick: () => editor!.chain().focus().toggleSuperscript().run(),
    },
  ];
  const otherButtons = [
    {
      icon: 'ri-list-unordered',
      onClick: () => editor!.chain().focus().toggleBulletList().run(),
    },
    {
      icon: 'ri-list-ordered',
      onClick: () => editor!.chain().focus().toggleOrderedList().run(),
    },
    {
      icon: 'ri-double-quotes-l',
      onClick: () => editor!.chain().focus().toggleBlockquote().run(),
    },
    {
      icon: 'ri-code-line',
      onClick: () => editor!.chain().focus().toggleCode().run(),
    },
    {
      icon: 'ri-code-box-line',
      onClick: () => editor!.chain().focus().toggleCodeBlock().run(),
    },
  ];

  const renderButtons = (buttons: { icon: string; onClick: () => void }[]) =>
    buttons.map((button, i) => (
      <ToolbarButton
        key={i}
        icon={button.icon}
        onClick={() => editor && button.onClick()}
      />
    ));

  return (
    <div className={`${CSSBlock}`}>
      <div className={`${CSSBlock}__content`}>
        <div className={`${CSSBlock}__section`}>
          <div className={`${CSSBlock}__section-heading`}>Styles</div>
          <div className={`${CSSBlock}__section-content`}>
            <div className={`${CSSBlock}__buttons-row`}>
              {renderButtons(headingButtons)}
            </div>
            {/* <div className={`${CSSBlock}__buttons-row`}>
              {renderButtons(headingButtons.slice(3))}
            </div> */}
          </div>
        </div>

        <div className={`${CSSBlock}__section`}>
          <div className={`${CSSBlock}__section-heading`}>Alignment</div>
          <div className={`${CSSBlock}__section-content`}>
            <div className={`${CSSBlock}__buttons-row`}>
              {renderButtons(alignButtons)}
            </div>
          </div>
        </div>

        <div className={`${CSSBlock}__section`}>
          <div className={`${CSSBlock}__section-heading`}>Typography</div>
          <div className={`${CSSBlock}__section-content`}>
            <div className={`${CSSBlock}__buttons-row`}>
              {renderButtons(stylesButtons)}
            </div>
          </div>
        </div>

        <div className={`${CSSBlock}__section`}>
          <div className={`${CSSBlock}__section-heading`}>Elements</div>
          <div className={`${CSSBlock}__section-content`}>
            <div className={`${CSSBlock}__buttons-row`}>
              {renderButtons(otherButtons)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
