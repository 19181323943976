import React, { useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import {
  EmdeeSettings,
  selectAllSettings,
} from '@core/store/settingsSlice/settingsSlice';
import { selectOfflineMode } from '@core/store/userSlice/userSlice';
import { createRequest } from '@helpers/appHelper';
import { setEmdeeSettings } from '@helpers/settingsHelper';
import NSCButton from '@nsc-ui/Button';
import NSCButtonSwitch from '@nsc-ui/ButtonSwitch';
import NSCCard from '@nsc-ui/Card/Card';
import NSCCardArea from '@nsc-ui/Card/CardArea';
import NSCCardButtonArea from '@nsc-ui/Card/CardButtonArea';
import NSCCardTitleArea from '@nsc-ui/Card/CardTitleArea';
import NSCModalCore, { NSCModalCoreRef } from '@nsc-ui/ModalCore';
import { useAuth } from '@providers/authProvider';

import SectionEditor from './partials/SectionEditor';
import SectionGeneral from './partials/SectionGeneral';
import SectionShortcuts from './partials/SectionShortcuts';

const CSSBlock = 'app-settings';

enum SETTINGS_SECTIONS {
  'general' = 'GENERAL',
  'editor' = 'EDITOR',
  'shortcuts' = 'SHORTCUTS',
}

interface SettingsProps {
  open: boolean;
  onClose: () => void;
}

const Settings: React.FC<SettingsProps> = ({ open, onClose }) => {
  const { user } = useAuth();
  const isOfflineMode = useAppSelector(selectOfflineMode);
  const dispatch = useAppDispatch();
  const appSettings: EmdeeSettings = useAppSelector(selectAllSettings);
  const [settings, setSettings] = useState<EmdeeSettings>(appSettings);

  const switchButtons = ['General', 'Editor', 'Shortcuts'];
  const [activeSection, setActiveSection] = useState(SETTINGS_SECTIONS.general);
  const modalRef = useRef<NSCModalCoreRef>(null);
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setSettings(appSettings);
  }, [appSettings]);

  const onSectionSwitch = (i: number) => {
    switch (i) {
      case 0:
        setActiveSection(SETTINGS_SECTIONS.general);
        break;
      case 1:
        setActiveSection(SETTINGS_SECTIONS.editor);
        break;
      case 2:
        setActiveSection(SETTINGS_SECTIONS.shortcuts);
        break;
    }
  };

  const modalButtons = [
    <NSCButton
      text="Cancel"
      outlined
      onClick={() => {
        setTimeout(() => {
          setActiveSection(SETTINGS_SECTIONS.general);
          setSettings(appSettings);
        }, 500);
        modalRef.current ? modalRef.current.gracefulClose(onClose) : onClose();
      }}
    />,
    <NSCButton
      text="Save"
      onClick={async () => {
        setTimeout(() => setActiveSection(SETTINGS_SECTIONS.general), 500);
        await createRequest(
          setEmdeeSettings({ dispatch, settings, isOfflineMode, user })
        );
        modalRef.current ? modalRef.current.gracefulClose(onClose) : onClose();
      }}
    />,
  ];

  useEffect(() => setIsOpen(open), [open]);

  return (
    <NSCModalCore
      ref={modalRef}
      isOpen={isOpen}
      onExited={() => {
        setIsOpen(false);
        onClose();
        setTimeout(() => setActiveSection(SETTINGS_SECTIONS.general), 500);
      }}>
      <NSCCard className={CSSBlock}>
        <NSCCardTitleArea
          titleText={
            <>
              e<strong>md</strong>ee Settings
            </>
          }>
          <div className={`${CSSBlock}-switcher`}>
            <NSCButtonSwitch
              buttons={switchButtons}
              onClick={onSectionSwitch}
            />
          </div>
        </NSCCardTitleArea>
        <NSCCardArea isDark className={`${CSSBlock}__content`}>
          {activeSection === SETTINGS_SECTIONS.general && (
            <SectionGeneral settings={settings} setSettings={setSettings} />
          )}
          {activeSection === SETTINGS_SECTIONS.editor && (
            <SectionEditor settings={settings} setSettings={setSettings} />
          )}
          {activeSection === SETTINGS_SECTIONS.shortcuts && (
            <SectionShortcuts />
          )}
        </NSCCardArea>
        <NSCCardButtonArea buttons={modalButtons}></NSCCardButtonArea>
      </NSCCard>
    </NSCModalCore>
  );
};

export default Settings;
