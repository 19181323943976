import React, { useEffect, useRef, useState } from 'react';

import NSCButton from '@nsc-ui/Button';
import NSCCard from '@nsc-ui/Card/Card';
import NSCCardArea from '@nsc-ui/Card/CardArea';
import NSCCardButtonArea from '@nsc-ui/Card/CardButtonArea';
import NSCModalCore, { NSCModalCoreRef } from '@nsc-ui/ModalCore';

interface ModalDeleteProps {
  filename: string;
  onClose: () => void;
  onConfirm: () => void;
}

const ModalDelete: React.FC<ModalDeleteProps> = ({
  filename,
  onClose,
  onConfirm,
}) => {
  const CSSBlock = 'modal-delete';
  const modalRef = useRef<NSCModalCoreRef>(null);
  const [isOpen, setIsOpen] = useState(true);

  const buttons = [
    <NSCButton
      key={0}
      text="No"
      outlined
      onClick={() =>
        modalRef.current ? modalRef.current.gracefulClose(onClose) : onClose()
      }
    />,
    <NSCButton
      key={1}
      text="Yes"
      onClick={() =>
        modalRef.current
          ? modalRef.current.gracefulClose(onConfirm)
          : onConfirm()
      }
    />,
  ];

  return (
    <NSCModalCore
      ref={modalRef}
      isOpen={isOpen}
      onExited={() => {
        setIsOpen(false);
        onClose();
      }}
      allowBackdropClose={false}>
      <NSCCard className={`${CSSBlock}`}>
        <NSCCardArea isDark>
          <div className={`${CSSBlock}__text`}>
            Are you sure you want to delete
            <br />
            {filename}
          </div>
          <div className={`${CSSBlock}__description`}>
            Warning: This action cannot be undone.
          </div>
        </NSCCardArea>
        <NSCCardButtonArea buttons={buttons} />
      </NSCCard>
    </NSCModalCore>
  );
};

export default ModalDelete;
