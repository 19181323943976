export default {
  type: 'doc',
  content: [
    {
      type: 'heading',
      attrs: {
        level: 1,
      },
      content: [
        {
          type: 'text',
          text: 'Ahoy!',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'Welcome to e',
        },
        {
          type: 'text',
          marks: [
            {
              type: 'bold',
            },
          ],
          text: 'md',
        },
        {
          type: 'text',
          text: 'ee.io.',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: "What is emdee.io? It's simple. Write something, rewrite it, you've probably made a mistake so write it again, read it back and (finally) love it.",
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: "Although it's already a perfect creation, it is still in a very early stage of development.",
        },
      ],
    },
    {
      type: 'paragraph',
    },
    {
      type: 'horizontalRule',
    },
    {
      type: 'paragraph',
    },
    {
      type: 'heading',
      attrs: {
        level: 2,
      },
      content: [
        {
          type: 'text',
          text: 'The secret sauce',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: "This is something super important. No, really. It's ",
        },
        {
          type: 'text',
          marks: [
            {
              type: 'bold',
            },
          ],
          text: 'super ultra mega',
        },
        {
          type: 'text',
          text: ' important!',
        },
      ],
    },
    {
      type: 'codeBlock',
      attrs: {
        language: null,
      },
      content: [
        {
          type: 'text',
          text: '// The following is the source code for Twitter\'s algorithm, as promised by Sir Elon.\n// Lets keep this hush hush.\n\nconst foo = (bar: string) => {\n  console.log("bar foo");\n};\n\nreturn foo;',
        },
      ],
    },
    {
      type: 'paragraph',
    },
    {
      type: 'horizontalRule',
    },
    {
      type: 'paragraph',
    },
    {
      type: 'heading',
      attrs: {
        level: 2,
      },
      content: [
        {
          type: 'text',
          text: 'About e',
        },
        {
          type: 'text',
          marks: [
            {
              type: 'bold',
            },
          ],
          text: 'md',
        },
        {
          type: 'text',
          text: 'ee.io',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'emdee.io is a free to use notebook which uses the Markdown syntax to quickly format your notes.',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: "Not sure what Markdown is? Let's give a quick example:",
        },
      ],
    },
    {
      type: 'codeBlock',
      attrs: {
        language: null,
      },
      content: [
        {
          type: 'text',
          text: '# Heading 01\nLorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nec metus venenatis turpis accumsan pellentesque. Aenean sed lectus hendrerit, rhoncus felis id, vestibulum leo.\n- List item 01\n- List item 02\n## Heading 02\n```enter code here```',
        },
      ],
    },
    {
      type: 'paragraph',
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'This outputs the following:',
        },
      ],
    },
    {
      type: 'heading',
      attrs: {
        level: 1,
      },
      content: [
        {
          type: 'text',
          text: 'Heading 01',
        },
      ],
    },
    {
      type: 'paragraph',
      content: [
        {
          type: 'text',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin nec metus venenatis turpis accumsan pellentesque. Aenean sed lectus hendrerit, rhoncus felis id, vestibulum leo.',
        },
      ],
    },
    {
      type: 'bulletList',
      content: [
        {
          type: 'listItem',
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: 'text',
                  text: 'List item 01',
                },
              ],
            },
          ],
        },
        {
          type: 'listItem',
          content: [
            {
              type: 'paragraph',
              content: [
                {
                  type: 'text',
                  text: 'List item 02',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'heading',
      attrs: {
        level: 2,
      },
      content: [
        {
          type: 'text',
          text: 'Heading 02',
        },
      ],
    },
    {
      type: 'codeBlock',
      attrs: {
        language: null,
      },
      content: [
        {
          type: 'text',
          text: 'enter code here',
        },
      ],
    },
  ],
};
