import { File } from '@helpers/fileHelper';
import {
  ThemeConfig,
  ThemeMode,
  ThemeModeConstants,
  defaultThemeConfig,
} from '@helpers/themeHelper';

import { AppSnapshot, AppSnapshotData } from './appSnapshot';

export interface AppSnapshot {
  activeTab: File;
}

export enum LSKeys {
  THEME_MODE = 'THEME_MODE',
  THEME_CONFIG = 'THEME_CONFIG',
  INDEX = 'USER_FILES',
  APP_SNAPSHOT = 'APPSNAPSHOT',
  SETTINGS = 'SETTINGS',
}

export class LocalStorage {
  static get(
    key: LSKeys
  ): ThemeMode | File[] | AppSnapshotData | ThemeConfig | undefined {
    if (key === LSKeys.APP_SNAPSHOT) {
      return AppSnapshot.get();
    } else if (key === LSKeys.THEME_CONFIG) {
      try {
        const val = localStorage.getItem(key);
        return val ? (JSON.parse(val) as ThemeConfig) : defaultThemeConfig;
      } catch (e) {
        console.error('LS', 'THEME_CONFIG', e);
        return defaultThemeConfig;
      }
    } else if (key === LSKeys.THEME_MODE) {
      const val = localStorage.getItem(key);
      switch (val) {
        case ThemeModeConstants.LIGHT:
          return ThemeModeConstants.LIGHT;
        case ThemeModeConstants.DARK:
          return ThemeModeConstants.DARK;
        default:
          return ThemeModeConstants.SYSTEM;
      }
    }
  }

  static set(
    key: LSKeys,
    value: ThemeMode | ThemeConfig | File[] | AppSnapshotData
  ) {
    if (key === LSKeys.APP_SNAPSHOT) {
      return AppSnapshot.set(value as AppSnapshotData);
    } else if (key === LSKeys.THEME_CONFIG) {
      return localStorage.setItem(key, JSON.stringify(value));
    } else if (key === LSKeys.THEME_MODE) {
      localStorage.setItem(key, value as ThemeMode);
    }
  }
}
