import React from 'react';

import Sidebar from '@components/sidebar/Sidebar';
import { selectZenMode } from '@core/store/settingsSlice/settingsSlice';

import { useAppSelector } from '../core/hooks/appHooks';
import Editor from './Editor';

interface HomeProps {
  isMounted: boolean;
}

const Home: React.FC<HomeProps> = ({ isMounted }) => {
  const CSSBlock = 'home';
  const zenMode = useAppSelector(selectZenMode);
  const mountedStyle = { animation: 'inAnimation 200ms ease-in-out' };
  const unmountedStyle = { animation: 'outAnimation 200ms ease-in-out' };

  return (
    <div
      className={`${CSSBlock} ${zenMode ? '--zen-mode' : ''}`}
      style={isMounted ? mountedStyle : unmountedStyle}>
      <Sidebar />
      <Editor />
    </div>
  );
};

export default Home;
