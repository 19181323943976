import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';

// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// npm install -g firebase-tools

// Firebase configuration
const firebaseConfig = {
  apiKey: process.env.EMDEE_FIREBASE_API_KEY,
  authDomain: process.env.EMDEE_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.EMDEE_FIREBASE_PROJECT_ID,
  storageBucket: process.env.EMDEE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.EMDEE_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.EMDEE_FIREBASE_APP_ID,
  measurementId: process.env.EMDEE_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

if (!IS_PRODUCTION) {
  // start emulator with: "firebase emulators:start --only auth"
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectFirestoreEmulator(db, 'localhost', 8080);
}
