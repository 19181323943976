import React, { useEffect, useRef, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectFileList } from '@core/store/appSlice/appSlice';
import { FileMetadata, Folder, isFolder } from '@helpers/fileHelper';
import NSCButton from '@nsc-ui/Button';
import NSCCard from '@nsc-ui/Card/Card';
import NSCCardArea from '@nsc-ui/Card/CardArea';
import NSCCardButtonArea from '@nsc-ui/Card/CardButtonArea';
import NSCModalCore, { NSCModalCoreRef } from '@nsc-ui/ModalCore';

interface ModalMoveFileProps {
  onClose: () => void;
  onConfirm: (targetFolder: Folder) => void;
}

const CSSBlock = 'modal-move-file';

const ModalMoveFile: React.FC<ModalMoveFileProps> = ({
  onClose,
  onConfirm,
}) => {
  const modalRef = useRef<NSCModalCoreRef>(null);
  const [isOpen, setIsOpen] = useState(true);
  const [targetFolder, setTargetFolder] = useState<Folder>();
  const fileList = useAppSelector(selectFileList);
  const [availableFolders, setAvailableFolders] = useState<Folder[]>([]);

  const buttons = [
    <NSCButton
      key={0}
      text="Cancel"
      outlined
      onClick={() =>
        modalRef.current ? modalRef.current.gracefulClose(onClose) : onClose()
      }
    />,
    <NSCButton
      key={1}
      text="Move Here"
      disabled={!targetFolder}
      onClick={() => {
        if (targetFolder)
          modalRef.current
            ? modalRef.current.gracefulClose(() => onConfirm(targetFolder))
            : onConfirm(targetFolder);
      }}
    />,
  ];

  const FolderButton = (folder: Folder, i: number = 0) => (
    <button
      className={`${CSSBlock}__folder ${
        targetFolder?.uuid === folder.uuid ? `${CSSBlock}__folder--active` : ''
      }`}
      onClick={() =>
        setTargetFolder(targetFolder?.uuid === folder.uuid ? undefined : folder)
      }
      key={i}>
      <span className={`${CSSBlock}__folder-icon`}>
        <i className="fa-solid fa-folder"></i>
      </span>
      <span className={`${CSSBlock}__folder-text`}>{folder.name}</span>
    </button>
  );

  useEffect(() => {
    setAvailableFolders(
      fileList.content.filter(item => isFolder(item)) as Folder[]
    );
  }, [fileList]);

  return (
    <NSCModalCore
      ref={modalRef}
      isOpen={isOpen}
      onExited={() => {
        setIsOpen(false);
        onClose();
      }}
      allowBackdropClose={false}>
      <NSCCard className={`${CSSBlock}`}>
        <NSCCardArea isDark>
          <div className={`${CSSBlock}__folder-list`}>
            {FolderButton({ uuid: '/', name: 'Root', items: [] })}
            {availableFolders.length > 0 ? (
              availableFolders.map((folder, i) => FolderButton(folder, i))
            ) : (
              <div className={`${CSSBlock}__no-folders`}>
                No folders available.
              </div>
            )}
            <NSCButton
              text="Create New Folder"
              outlined
              faIcon="fa-plus"
              onClick={() => {}}
            />
          </div>
        </NSCCardArea>
        <NSCCardButtonArea buttons={buttons} />
      </NSCCard>
    </NSCModalCore>
  );
};

export default ModalMoveFile;
