import { doc, getDoc, updateDoc } from 'firebase/firestore';

import { File, FileMetadata, File_MD } from '@helpers/fileHelper';
import { FSCollections } from '@providers/firestoreProvider';
import { JSONContent } from '@tiptap/core';

export default class FSFileMD {
  /**
   *
   * @param file
   * @param content
   * @returns
   */
  static async updateContent(
    file: File | FileMetadata,
    content: JSONContent
  ): Promise<File_MD | undefined> {
    const fileRef = doc(FSCollections.Files, file.uuid);
    const docSnap = await getDoc(fileRef);

    if (docSnap.exists()) {
      const updatedFile: File_MD = { ...file, content };
      await updateDoc(fileRef, { content: content });
      return updatedFile;
    } else {
      console.error('FSFileMD', 'updateContent', 'File not found!');
    }
  }
}
