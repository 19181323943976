import React, { useEffect, useState } from 'react';

import pkg from '../../../package.json';

interface LoadingIntroProps {
  onAnimStart?: () => void;
  onAnimEnd: () => void;
}

const LoadingIntro: React.FC<LoadingIntroProps> = ({
  onAnimStart,
  onAnimEnd,
}) => {
  const CSSBlock = 'loading-intro';
  const [hide, setHide] = useState(false);

  const elements = [
    { weight: 400, char: 'e' },
    { weight: 700, char: 'm' },
    { weight: 700, char: 'd' },
    { weight: 400, char: 'e' },
    { weight: 400, char: 'e' },
    { weight: 400, char: '.' },
    { weight: 400, char: 'i' },
    { weight: 400, char: 'o' },
  ];

  useEffect(() => {
    setTimeout(() => {
      onAnimStart && onAnimStart();
      setHide(true);
      setTimeout(() => onAnimEnd(), 500);
    }, 2400);
  }, []);

  return (
    <div className={`${CSSBlock} ${hide ? `${CSSBlock}--hide` : ''}`}>
      <div className={`${CSSBlock}__background`}></div>
      <div className={`${CSSBlock}__title`}>
        {elements.map((el, i) => (
          <span
            key={i}
            className={`${CSSBlock}__title-text`}
            style={{
              fontWeight: el.weight,
              animationDelay: `${0.1 + 0.2 * i}s`,
            }}>
            {el.char}
          </span>
        ))}
        <span className={`${CSSBlock}__title-underline`}></span>
      </div>
      <div className={`${CSSBlock}__subtitle`}>
        v{pkg.version} alpha - {pkg.date}
      </div>
      <div className={`${CSSBlock}__loading-indicator`}></div>
    </div>
  );
};

export default LoadingIntro;
