import React, { useState } from 'react';

const SectionShortcuts: React.FC = ({}) => {
  const CSSBlock = 'app-settings';

  return (
    <>
      <div className={`${CSSBlock}__section`}>
        <div
          style={{
            textAlign: 'center',
            lineHeight: '10rem',
          }}>
          Coming soon.
        </div>
      </div>
    </>
  );
};

export default SectionShortcuts;
