import { useEffect, useState } from 'react';

import { useAppDispatch } from '@core/hooks/appHooks';
import { setEditor } from '@core/store/pageSlice/pageSlice';
import useDebounce from '@hooks/useDebounce';
import Placeholder from '@tiptap/extension-placeholder';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { Editor, EditorContent, JSONContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

interface TiptapProps {
  content: JSONContent | null;
  onUpdate: (content: JSONContent) => void;
}

const Tiptap: React.FC<TiptapProps> = ({ content, onUpdate }) => {
  const [value, setValue] = useState<JSONContent | null>(content || null);
  const debouncedValue = useDebounce<JSONContent>(value || {}, 200);

  const dispatch = useAppDispatch();

  const editor: Editor | null = useEditor({
    extensions: [
      StarterKit,
      Placeholder.configure({
        placeholder: 'Begin your note journey…',
      }),
      Subscript,
      Superscript,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Underline,
    ],
    content: content,
    onUpdate: ({ editor }) => {
      setValue(editor.getJSON());
    },
  });

  // TO-DO: Remove hack
  useEffect(() => {
    editor?.commands.setContent(content);
  }, []);

  useEffect(() => {
    dispatch(setEditor(editor));
  }, [editor]);

  useEffect(() => {
    onUpdate(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    editor?.commands.setContent(content);
  }, [content]);

  return <EditorContent editor={editor} />;
};

export default Tiptap;
