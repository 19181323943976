import React, { useEffect, useState } from 'react';

import NSCCard from '@nsc-ui/Card/Card';
import NSCCardArea from '@nsc-ui/Card/CardArea';
import { useAuth } from '@providers/authProvider';

import OAuthButton from './OAuthButton';

interface AuthenticationProps {
  onClickOfflineMode: () => void;
  isMounted: boolean;
}

const Authentication: React.FC<AuthenticationProps> = ({
  onClickOfflineMode,
  isMounted,
}) => {
  const CSSBlock = 'authentication';
  const { signInGoogle, signInGitHub } = useAuth();
  const [isVisible, setIsVisible] = useState(false);

  const mountedStyle = { animation: 'inAnimation 200ms ease-in-out' };
  const unmountedStyle = { animation: 'outAnimation 200ms ease-in-out' };

  const onClickGoogle = () => {
    try {
      signInGoogle();
      // storeCred(cred);
    } catch (err) {
      console.log(err);
    }
  };
  const onClickGitHub = () => signInGitHub();

  useEffect(() => {
    setIsVisible(true);
    return () => setIsVisible(false);
  }, []);

  return (
    <div
      className={`${CSSBlock}__wrapper ${
        !isVisible ? `${CSSBlock}--hide` : ''
      }`}
      style={isMounted ? mountedStyle : unmountedStyle}>
      <NSCCard>
        <div className={`${CSSBlock}`}>
          <NSCCardArea>
            <div className={`${CSSBlock}__content`}>
              <div className={`${CSSBlock}__title`}>Welcome back!</div>
              <div className={`${CSSBlock}__description`}>
                Select an account to sign in with.
              </div>
              <div className={`${CSSBlock}__description`}>
                This will allow us to keep any changes up-to-date across your
                devices.
              </div>
              <div className={`${CSSBlock}__oauth-area`}>
                <OAuthButton
                  onClick={onClickGoogle}
                  icon="fa-google"></OAuthButton>
                <OAuthButton
                  disabled
                  onClick={onClickGoogle}
                  icon="fa-apple"></OAuthButton>
                <OAuthButton
                  onClick={onClickGitHub}
                  icon="fa-github"></OAuthButton>
              </div>
            </div>
          </NSCCardArea>
          <NSCCardArea isDark>
            <div className={`${CSSBlock}__switch`}>
              Don't want to sign in?{' '}
              <span
                onClick={() => {
                  setIsVisible(false);
                  onClickOfflineMode();
                }}>
                Use in offline mode
              </span>
            </div>
          </NSCCardArea>
        </div>
      </NSCCard>
    </div>
  );
};

export default Authentication;
