import React, { useState } from 'react';

import { EmdeeSettings } from '@core/store/settingsSlice/settingsSlice';
import NSCToggle from '@nsc-ui/Toggle';

// import NSCItemSwitch from '@nsc-ui/ItemSwitch';
// import LogoLight from '@assets/logo-white.svg';

interface SectionGeneralProps {
  settings: EmdeeSettings;
  setSettings: React.Dispatch<React.SetStateAction<EmdeeSettings>>;
}

const SectionGeneral: React.FC<SectionGeneralProps> = ({
  settings,
  setSettings,
}) => {
  const CSSBlock = 'app-settings';

  // const items = [
  //   <img src={LogoLight} style={{ width: '80px' }} />,
  //   <img src={LogoLight} style={{ width: '40px' }} />,
  //   <i className={`fa-solid fa-gear`} style={{ height: '4rem' }}></i>,
  // ];

  const onToggleZen = (state: boolean) =>
    setSettings({
      ...settings,
      general: { ...settings.general, zenMode: state },
    });

  return (
    <>
      <div className={`${CSSBlock}__section`}>
        <div className={`${CSSBlock}__section-heading`}>App Interface</div>
        <div className={`${CSSBlock}__section-content`}>
          <NSCToggle
            text="Enable zen mode"
            onToggle={onToggleZen}
            isActive={settings.general.zenMode}
          />
          <NSCToggle
            text="Enable preview features"
            disabled
            onToggle={() => {}}
          />
        </div>
      </div>
      {/* <NSCItemSwitch items={items} onClick={() => {}} /> */}
      {/* <div className={`${CSSBlock}__section`}>
        <div className={`${CSSBlock}__section-content`}>
          <NSCItemSwitch items={items} onClick={() => {}} />
        </div>
      </div> */}
    </>
  );
};

export default SectionGeneral;
