import { FileTypes, File_MDX } from '@helpers/fileHelper';

export default class emdeeFileMDX {
  static create(name: string, tabName: string): File_MDX {
    return {
      uuid: crypto.randomUUID(),
      type: FileTypes.COLLECTION,
      name,
      tabs: [{ name: tabName }],
      activeTab: 0,
    };
  }
}
