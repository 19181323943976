import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectActiveFile } from '@core/store/appSlice/appSlice';
import { selectOfflineMode } from '@core/store/userSlice/userSlice';
import { createRequest } from '@helpers/appHelper';
import { File, FileTypes, File_MD, File_MDX } from '@helpers/fileHelper';
import EmdeeStorage, { EmdeeStorageType } from '@storage/emdee/emdeeStorage';
import { JSONContent } from '@tiptap/react';

import Page_MD from './partials/_Page_MD';
import Page_MDX from './partials/_Page_MDX';

const Page: React.FC = () => {
  const CSSBlock = 'page';
  const isOfflineMode = useAppSelector(selectOfflineMode);
  const fileMetadata = useAppSelector(selectActiveFile);
  const [file, setFile] = useState<File | undefined>(undefined);
  const [type, setType] = useState<FileTypes | undefined>(undefined);

  useEffect(() => {
    if (fileMetadata?.uuid) {
      createRequest(
        EmdeeStorage.loadFile(
          isOfflineMode ? EmdeeStorageType.LOCAL : EmdeeStorageType.FIRESTORE,
          fileMetadata?.uuid
        )
      ).then(file => {
        setFile(file);
        setType(fileMetadata.type);
      });
    }
  }, [fileMetadata]);

  const onUpdate = async (content: JSONContent, activeTab?: number) => {
    if (file) {
      if (file.type === FileTypes.COLLECTION && activeTab === undefined) return;
      // await createRequest(
      EmdeeStorage.updateFile(
        isOfflineMode ? EmdeeStorageType.LOCAL : EmdeeStorageType.FIRESTORE,
        file,
        content,
        activeTab
      );
      // );
    }
  };

  const doReload = async () => {
    if (fileMetadata?.uuid) {
      const file = await await createRequest(
        EmdeeStorage.loadFile(
          isOfflineMode ? EmdeeStorageType.LOCAL : EmdeeStorageType.FIRESTORE,
          fileMetadata?.uuid
        )
      );
      setFile(file);
    }
  };

  return (
    <div
      className={`${CSSBlock} ${!file ? `${CSSBlock}--no-file-selected` : ''}`}>
      {file && type && type === FileTypes.COLLECTION ? (
        <Page_MDX
          CSSBlock={CSSBlock}
          file={file as File_MDX}
          onUpdate={onUpdate}
          doReload={doReload}
        />
      ) : (
        <Page_MD
          CSSBlock={CSSBlock}
          file={file as File_MD}
          onUpdate={onUpdate}
        />
      )}
    </div>
  );
};

export default Page;
