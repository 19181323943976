import { User } from 'firebase/auth';

import { Folder } from '@helpers/fileHelper';

import FSIndex from './_index';

export default class FSFolder {
  static create = async (user: User, name: string): Promise<Folder> => {
    const folder = {
      uuid: crypto.randomUUID(),
      name,
      items: [],
    };
    await FSIndex.addFolder(user, folder);
    return folder;
  };
}
