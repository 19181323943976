import React from 'react';

interface OAuthButtonProps {
  icon: string;
  disabled?: boolean;
  onClick: () => void;
}

const OAuthButton: React.FC<OAuthButtonProps> = ({
  icon,
  disabled,
  onClick,
}) => {
  const CSSBlock = 'oauth-button';

  return (
    <button
      className={`${CSSBlock} ${CSSBlock}--${icon}`}
      disabled={disabled}
      onClick={onClick}>
      <span className={`${CSSBlock}__logo`}>
        <i className={`fa-brands ${icon}`}></i>
      </span>
    </button>
  );
};

export default OAuthButton;
