import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { selectOfflineMode } from '@core/store/userSlice/userSlice';
import { createRequest } from '@helpers/appHelper';
import { FileMetadata, FileTypes, refreshFileList } from '@helpers/fileHelper';
import NSCIconButton, { IconButtonSize } from '@nsc-ui/IconButton';
import { useAuth } from '@providers/authProvider';
import EmdeeStorage, { EmdeeStorageType } from '@storage/emdee/emdeeStorage';

import ModalInput from '../../modals/ModalInput';
import FileBrowserList from './FileBrowserList';

enum MODALS {
  NEW_FILE = 'new-file',
  NEW_FILE_COLLECTION = 'new-file-collection',
  NEW_FILE_COLLECTION_TAB = 'new-file-collection-tab',
  NEW_FOLDER = 'new-folder',
}

const FileBrowser: React.FC = ({}) => {
  const CSSBlock = 'section';
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const isOfflineMode = useAppSelector(selectOfflineMode);
  const storageMode = isOfflineMode
    ? EmdeeStorageType.LOCAL
    : EmdeeStorageType.FIRESTORE;

  const [activeModal, setActiveModal] = useState<undefined | MODALS>(undefined);
  const [collectionName, setCollectionName] = useState<string>();

  const onCreateFile = (name: string) => {
    if (name.replaceAll(' ', '').length > 0) {
      createRequest(
        EmdeeStorage.createFile(
          storageMode,
          { name, type: FileTypes.MARKDOWN },
          user
        )
      ).then(newFile => {
        if (newFile) postCreate(newFile);
      });
    }
  };

  const onStartFileCollection = (name: string) => {
    if (name.replaceAll(' ', '').length > 0) {
      setCollectionName(name);
      setActiveModal(MODALS.NEW_FILE_COLLECTION_TAB);
    }
  };

  const onCreateFileCollection = (tabName: string) => {
    if (collectionName && tabName.replaceAll(' ', '').length > 0) {
      createRequest(
        EmdeeStorage.createFile(
          storageMode,
          { name: collectionName, tabName, type: FileTypes.COLLECTION },
          user
        )
      ).then(newFile => {
        if (newFile) postCreate(newFile);
      });
    }
  };

  const onCreateFolder = (name: string) => {
    if (name.replaceAll(' ', '').length > 0) {
      createRequest(
        EmdeeStorage.createFolder(storageMode, name, user).then(() => {
          postCreate();
        })
      );
    }
  };

  const postCreate = async (activeFile?: FileMetadata) => {
    await createRequest(
      refreshFileList(dispatch, storageMode, user, activeFile)
    );
    setActiveModal(undefined);
  };

  return (
    <>
      <div className={`${CSSBlock}`}>
        <div className={`${CSSBlock}__title-row`}>
          <div className={`${CSSBlock}__title`}>My Files</div>
          <div className={`${CSSBlock}__buttons`}>
            <NSCIconButton
              size={IconButtonSize.SMALL}
              faIcon="fa-regular fa-file"
              onClick={() => setActiveModal(MODALS.NEW_FILE)}
            />
            <NSCIconButton
              size={IconButtonSize.SMALL}
              faIcon="fa-regular fa-file-code"
              onClick={() => setActiveModal(MODALS.NEW_FILE_COLLECTION)}
            />
            <NSCIconButton
              size={IconButtonSize.SMALL}
              faIcon="fa-regular fa-folder"
              onClick={() => setActiveModal(MODALS.NEW_FOLDER)}
            />
          </div>
        </div>
        <FileBrowserList />
      </div>

      {activeModal === MODALS.NEW_FILE && (
        <ModalInput
          placeholder="Give your file a name"
          onClose={() => setActiveModal(undefined)}
          onConfirm={onCreateFile}
        />
      )}
      {activeModal === MODALS.NEW_FILE_COLLECTION && (
        <ModalInput
          placeholder="Give your file a name"
          onClose={() => setActiveModal(undefined)}
          onConfirm={onStartFileCollection}
        />
      )}
      {activeModal === MODALS.NEW_FILE_COLLECTION_TAB && (
        <ModalInput
          placeholder="Name your first tab"
          onClose={() => setActiveModal(undefined)}
          onConfirm={onCreateFileCollection}
        />
      )}
      {activeModal === MODALS.NEW_FOLDER && (
        <ModalInput
          placeholder="Give your folder a name"
          onClose={() => setActiveModal(undefined)}
          onConfirm={onCreateFolder}
        />
      )}
    </>
  );
};

export default FileBrowser;
