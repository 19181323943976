import { User } from 'firebase/auth';
import { deleteDoc, updateDoc } from 'firebase/firestore';

import { doc, getDoc, setDoc } from '@firebase/firestore';
import { File, Folder } from '@helpers/fileHelper';
import { FSCollections } from '@providers/firestoreProvider';

import { db } from '../../firebase';
import FSIndex from './_index';
import { FSCollectionName, FSCollection_File } from './_types';

export default class FSFiles {
  static getFileDoc = async (
    uuid: string
  ): Promise<FSCollection_File | undefined> => {
    const fileRef = doc(FSCollections.Files, uuid);
    const docSnap = await getDoc(fileRef);
    return docSnap.exists()
      ? (docSnap.data() as FSCollection_File)
      : (console.error('File not found!', uuid), undefined);
  };

  static createFileDoc = async (
    file: FSCollection_File
  ): Promise<FSCollection_File> => {
    await setDoc(doc(db, FSCollectionName.FILES, file.uuid), file);
    return file;
  };

  static async saveFile(user: User, file: File, folderUUID?: string) {
    await FSFiles.createFileDoc(file);
    await FSIndex.addFile(user, file, folderUUID);
  }

  static async renameFile(user: User, file: File, newName: string) {
    const fileRef = doc(FSCollections.Files, file.uuid);
    await updateDoc(fileRef, { name: newName });
    await FSIndex.renameFile(user, file, newName);
  }

  static async moveFile(user: User, file: File, targetFoler: Folder) {
    await FSIndex.moveFile(user, file, targetFoler);
  }

  static async deleteFile(user: User, file: File) {
    const fileRef = doc(FSCollections.Files, file.uuid);
    await deleteDoc(fileRef);
    await FSIndex.deleteFile(user, file);
  }
}
