import { FileMetadata, File_MDX } from '@helpers/fileHelper';
import { JSONContent } from '@tiptap/react';

import { getFileStorageKey } from '../emdee/_common';
import LSFiles from './_file';

export default class LSFileMDX {
  static updateContent(
    file: FileMetadata,
    tab: number,
    content: JSONContent
  ): File_MDX | undefined {
    if (tab < 0) return;

    const existingFile = LSFiles.load(file.uuid) as File_MDX;
    if (existingFile) {
      const tabs = existingFile.tabs;
      tabs[tab].content = content;
      const updatedFile = { ...existingFile, tabs };
      localStorage.setItem(
        getFileStorageKey(file.uuid),
        JSON.stringify(updatedFile)
      );
      return updatedFile;
    } else {
      console.error('LSFileMDX', 'updateContent', 'File not found!');
    }
  }

  static createTab(file: FileMetadata, tabName: string): number {
    const existingFile = LSFiles.load(file.uuid) as File_MDX;
    if (existingFile) {
      const tabs = existingFile.tabs;
      const newIndex = tabs.push({ name: tabName });
      localStorage.setItem(
        getFileStorageKey(file.uuid),
        JSON.stringify({ ...existingFile, tabs, activeTab: newIndex - 1 })
      );
      return newIndex - 1;
    } else {
      console.error('LSFileMDX', 'createTab', 'File not found!');
      return -1;
    }
  }

  static renameTab(file: File_MDX, tab: number, name: string) {
    if (tab > -1 && file.tabs[tab]) {
      file.tabs[tab].name = name;
      localStorage.setItem(getFileStorageKey(file.uuid), JSON.stringify(file));
    }
  }

  static deleteTab(file: File_MDX, tab: number) {
    if (tab > -1 && file.tabs[tab]) {
      file.tabs.splice(tab, 1);
      if (file.activeTab >= file.tabs.length - 1) {
        file.activeTab = file.tabs.length - 1;
      }
      localStorage.setItem(getFileStorageKey(file.uuid), JSON.stringify(file));
    }
  }

  static setActiveTab(file: File_MDX, tab: number) {
    const existingFile = LSFiles.load(file.uuid) as File_MDX;
    if (existingFile) {
      const updatedFile = { ...existingFile, activeTab: tab };
      localStorage.setItem(
        getFileStorageKey(file.uuid),
        JSON.stringify(updatedFile)
      );
      return updatedFile;
    } else {
      console.error('LSFileMDX', 'updateContent', 'File not found!');
    }
  }
}
