import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from '../store';

export interface EmdeeSettingsGeneral {
  enableExperimental: boolean;
  zenMode: boolean;
}
export interface EmdeeSettingsEditor {
  showToolbar: boolean;
  showMarkdownHints: boolean;
  // margins:
  // tiptap: {}
}

export interface EmdeeSettings {
  general: EmdeeSettingsGeneral;
  editor: EmdeeSettingsEditor;
  shortcuts: {};
}

const initialState: EmdeeSettings = {
  general: {
    enableExperimental: false,
    zenMode: false,
  },
  editor: {
    showToolbar: true,
    showMarkdownHints: true,
  },
  shortcuts: {},
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    /**
     *
     */
    setSettings: (state, action: PayloadAction<EmdeeSettings>) => {
      state.general = action.payload.general;
      state.editor = action.payload.editor;
    },
    setZenMode: (state, action: PayloadAction<boolean>) => {
      state.general.zenMode = action.payload;
    },
    setShowToolbar: (state, action: PayloadAction<boolean>) => {
      state.editor.showToolbar = action.payload;
    },
    setShowMarkdownHints: (state, action: PayloadAction<boolean>) => {
      state.editor.showMarkdownHints = action.payload;
    },
  },
});

export const { setSettings, setZenMode, setShowToolbar, setShowMarkdownHints } =
  settingsSlice.actions;

export const selectAllSettings = (state: RootState) => state.settings;
export const selectZenMode = (state: RootState) =>
  state.settings.general.zenMode;
export const selectShowToolbar = (state: RootState) =>
  state.settings.editor.showToolbar;
export const selectShowMarkdownHints = (state: RootState) =>
  state.settings.editor.showMarkdownHints;

export default settingsSlice.reducer;
