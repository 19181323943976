import {
  Auth,
  GithubAuthProvider,
  GoogleAuthProvider,
  User,
  signInWithRedirect,
  signOut,
} from 'firebase/auth';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { auth } from '../firebase';

export interface AuthProviderProps {
  children?: ReactNode;
}

export interface UserContextState {
  isAuthenticated: boolean;
  isLoading: boolean;
  id?: string;
}

export const UserStateContext = createContext<UserContextState>(
  {} as UserContextState
);

export const useUserContext = (): UserContextState => {
  return useContext(UserStateContext);
};

export interface AuthContextModel {
  auth: Auth;
  user: User | null;
  signInGoogle: () => void;
  signInGitHub: () => void;
  logOut: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextModel>(
  {} as AuthContextModel
);

export function useAuth(): AuthContextModel {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [user, setUser] = useState<User | null>(null);

  const signInGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  };

  const signInGitHub = () => {
    const provider = new GithubAuthProvider();
    signInWithRedirect(auth, provider);
  };

  const logOut = (): Promise<void> => signOut(auth);

  useEffect(() => {
    //function that firebase notifies you if a user is set
    const unsubsrcibe = auth.onAuthStateChanged(user => {
      console.group('AuthProvider');
      console.log(user);
      console.log(user?.uid);
      console.groupEnd();
      setUser(user);
    });
    return unsubsrcibe;
  }, []);

  const values = {
    auth,
    user,
    signInGoogle,
    signInGitHub,
    logOut,
  };
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};
