import React, { useEffect, useState } from 'react';

import { File_MDX } from '@helpers/fileHelper';
import { JSONContent } from '@tiptap/react';

import Tiptap from './TipTap';
import Header_MDX from './_Header_MDX';

interface Page_MDXProps {
  CSSBlock: string;
  file: File_MDX;
  onUpdate: (content: JSONContent, activeTab: number) => void;
  doReload: () => void;
}

const Page_MDX: React.FC<Page_MDXProps> = ({
  CSSBlock,
  file,
  onUpdate,
  doReload,
}) => {
  const [activeTab, setActiveTab] = useState((file as File_MDX).activeTab);

  useEffect(() => {
    setActiveTab(file.activeTab);
  }, [file.activeTab]);

  // On navigate to another tab, update file contents from memory.
  useEffect(() => {
    doReload();
  }, [activeTab]);

  return (
    <>
      <Header_MDX
        file={file}
        doReload={doReload}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <div className={`${CSSBlock}__background`}>
        <div className={`${CSSBlock}__content`}>
          {file && file.tabs[activeTab] && (
            <Tiptap
              content={file.tabs[activeTab].content}
              onUpdate={content => onUpdate(content, activeTab)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Page_MDX;
