import React, { useRef, useState } from 'react';

import useComponentVisible from '@core/hooks/useComponentVisible';
import NSCCardArea from '@nsc-ui/Card/CardArea';

import MenuCore from './MenuCore';
import MenuItem from './MenuItem';
import { MenuContent, MenuSection } from './MenuTypes';

const CSSBlock = 'nsc-menu';

const contents = (sections: MenuSection[], onClick: (id: string) => void) =>
  sections.map((section, i) => (
    <NSCCardArea key={i} isDark={section.isDark}>
      {section.heading && (
        <div className={`${CSSBlock}__section-heading`}>{section.heading}</div>
      )}
      <div className={`${CSSBlock}__section-items`}>
        {section.items.map((item, j) => (
          <MenuItem key={j} item={item} onClick={onClick} />
        ))}
      </div>
    </NSCCardArea>
  ));

interface MenuProps {
  content: MenuContent;
  triggerEl: JSX.Element;
  onClick: (id: string) => void;
}

const Menu: React.FC<MenuProps> = ({ content, triggerEl, onClick }) => {
  const triggerRef = useRef<HTMLButtonElement>(null);
  const menuCoreRef = useRef<any>(null);
  const [triggerBounds, setTriggerBounds] = useState<DOMRect>();

  const gracefulClose = (onClose: () => void) => {
    menuCoreRef.current
      ? menuCoreRef.current.gracefulClose(onClose)
      : onClose();
  };

  const { ref, isVisible, setIsVisible } = useComponentVisible(
    false,
    () => new Promise<any>(resolve => gracefulClose(() => resolve(0)))
  );

  triggerEl = React.cloneElement(triggerEl, {
    onClick: () => {
      isVisible ? gracefulClose(() => setIsVisible(false)) : setIsVisible(true);
      if (triggerRef.current)
        setTriggerBounds(triggerRef.current.getBoundingClientRect());
    },
    ref: triggerRef,
  });

  const onItemClick = (id: string) => {
    onClick(id);
    gracefulClose(() => setIsVisible(false));
  };

  return (
    <div>
      <span style={{ marginLeft: 'auto' }}>{triggerEl}</span>
      {triggerBounds && (
        <MenuCore
          ref={menuCoreRef}
          onExited={() => setIsVisible(false)}
          isOpen={isVisible}
          triggerBounds={triggerBounds}>
          <div ref={ref}>{contents(content.sections, onItemClick)}</div>
        </MenuCore>
      )}
    </div>
  );
};

export default Menu;
