import React, { CSSProperties, useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import {
  selectThemeConfig,
  setThemeConfig,
} from '@core/store/appSlice/appSlice';
import { selectOfflineMode } from '@core/store/userSlice/userSlice';
import {
  CSSVARS,
  ThemeConfig,
  setAppBackground,
  setCSSVar,
  setTheme,
} from '@helpers/themeHelper';
import NSCButton from '@nsc-ui/Button';
import NSCCard from '@nsc-ui/Card/Card';
import NSCCardArea from '@nsc-ui/Card/CardArea';
import NSCCardButtonArea from '@nsc-ui/Card/CardButtonArea';
import NSCCardTitleArea from '@nsc-ui/Card/CardTitleArea';
import NSCModalCore, { NSCModalCoreRef } from '@nsc-ui/ModalCore';
import Slider from '@nsc-ui/Slider';
import { useAuth } from '@providers/authProvider';
import { FSUser } from '@storage/firebase/firestore';
import { LSKeys, LocalStorage } from '@storage/localStorage/localStorage';

import HueRing from './partials/HueRing';
import ThemeSwitcher from './partials/ThemeSwitcher';

const CSSBlock = 'theme-configurator';

interface ThemeConfiguratorProps {
  open: boolean;
  onClose: () => void;
}

const ThemeConfigurator: React.FC<ThemeConfiguratorProps> = ({
  open,
  onClose,
}) => {
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const themeConfig = useAppSelector(selectThemeConfig);
  const isOfflineMode = useAppSelector(selectOfflineMode);

  const [hueValues, setHueValues] = useState(themeConfig.hue);
  const [intensityValue, setIntensityValue] = useState(themeConfig.intensity);
  const [grainValue, setGrainValue] = useState(themeConfig.grain);
  const modalRef = useRef<NSCModalCoreRef>(null);
  const [isOpen, setIsOpen] = useState(open);

  const onSave = () => {
    const updatedTheme: ThemeConfig = {
      ...themeConfig,
      hue: [...hueValues],
      intensity: intensityValue,
      grain: grainValue,
    };
    setTheme({ dispatch, theme: updatedTheme, isOfflineMode, user });
  };

  const setDefaultValues = () => {
    setHueValues(themeConfig.hue);
    setIntensityValue(themeConfig.intensity);
    setGrainValue(themeConfig.grain);
  };

  useEffect(() => {
    setDefaultValues();
  }, [themeConfig]);

  const modalButtons = [
    <NSCButton
      text="Cancel"
      outlined
      onClick={() => {
        setTimeout(() => setDefaultValues(), 0);
        modalRef.current ? modalRef.current.gracefulClose(onClose) : onClose();
      }}
    />,
    <NSCButton
      text="Save"
      onClick={() => {
        onSave();
        modalRef.current ? modalRef.current.gracefulClose(onClose) : onClose();
      }}
    />,
  ];

  useEffect(() => setIsOpen(open), [open]);

  useEffect(() => {
    setCSSVar(CSSVARS.THEME_CONFIG_INTENSITY, intensityValue);
  }, [intensityValue]);

  useEffect(() => {
    setCSSVar(CSSVARS.THEME_CONFIG_GRAIN, grainValue);
  }, [grainValue]);

  return (
    <NSCModalCore
      ref={modalRef}
      isOpen={isOpen}
      onExited={() => {
        setIsOpen(false);
        onClose();
      }}>
      <NSCCard className={`${CSSBlock}`}>
        <NSCCardTitleArea titleText="Theme Configurator">
          <ThemeSwitcher />
        </NSCCardTitleArea>

        <NSCCardArea isDark>
          <div
            className={`${CSSBlock}__pickers`}
            style={
              {
                '--theme-config-intensity': intensityValue.toString(),
                '--theme-config-grain': grainValue.toString(),
              } as CSSProperties
            }>
            <HueRing hues={hueValues} onChange={hues => setHueValues(hues)} />
            <div className={`${CSSBlock}__sliders`}>
              <div className={`${CSSBlock}__intensity`}>
                <div className={`${CSSBlock}__subtitle`}>Intensity</div>
                <Slider
                  onChange={val => setIntensityValue(val)}
                  initialValue={intensityValue}
                />
              </div>
              <div className={`${CSSBlock}__grain`}>
                <div className={`${CSSBlock}__subtitle`}>Texture</div>
                <Slider
                  onChange={val => setGrainValue(val)}
                  initialValue={grainValue}
                />
              </div>
            </div>
          </div>
        </NSCCardArea>

        <NSCCardButtonArea buttons={modalButtons}></NSCCardButtonArea>
      </NSCCard>
    </NSCModalCore>
  );
};

export default ThemeConfigurator;
