import React, { HTMLProps, PropsWithChildren } from 'react';

import NSCCardArea from './CardArea';

interface NSCCardTitleAreaProps
  extends PropsWithChildren,
    HTMLProps<HTMLDivElement> {
  titleText: string | JSX.Element;
}

const NSCCardTitleArea: React.FC<NSCCardTitleAreaProps> = ({
  children,
  titleText,
  ...props
}) => {
  const CSSBlock = 'nsc-card';

  return (
    <NSCCardArea
      {...props}
      className={`${CSSBlock}__area-title ${
        props.className ? props.className : ``
      }`}>
      <div className={`${CSSBlock}__title`}>{titleText}</div>
      {children}
    </NSCCardArea>
  );
};

export default NSCCardTitleArea;
