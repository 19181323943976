import React from 'react';
import { useEffect } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectActiveFile } from '@core/store/appSlice/appSlice';
import useComponentVisible from '@hooks/useComponentVisible';
import NSCIconButton, { IconButtonSize } from '@nsc-ui/IconButton';

import FileBrowser from './partials/FileBrowser';
import OpenTabs from './partials/OpenTabs';
import SidebarHeading from './partials/SidebarHeading';

const Sidebar: React.FC = () => {
  const CSSBlock = 'sidebar';
  const { ref, isVisible, setIsVisible } = useComponentVisible(false);

  const activeTab = useAppSelector(selectActiveFile);

  useEffect(() => {
    setIsVisible(false);
  }, [activeTab]);

  return (
    <>
      <div
        className={`${CSSBlock} ${isVisible ? `${CSSBlock}--open` : ''}`}
        ref={ref}>
        <NSCIconButton
          className={`${CSSBlock}__trigger ${CSSBlock}__trigger--collapsed`}
          faIcon="fa-regular fa-copy"
          size={IconButtonSize.SMALL}
          onClick={() => setIsVisible(!isVisible)}
        />
        <NSCIconButton
          className={`${CSSBlock}__trigger ${CSSBlock}__trigger--expanded`}
          faIcon="fa-xmark"
          onClick={() => setIsVisible(!isVisible)}
        />
        <div className={`${CSSBlock}__content`}>
          <SidebarHeading />
          {/* <div className={`${CSSBlock}__divider`}></div>
          <OpenTabs /> */}
          <div className={`${CSSBlock}__divider`}></div>
          <FileBrowser />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
