import React, { HTMLProps, PropsWithChildren } from 'react';

interface NSCCardProps extends PropsWithChildren, HTMLProps<HTMLDivElement> {
  buttons?: JSX.Element[];
  title?: string;
}

const NSCCard: React.FC<NSCCardProps> = ({
  children,
  buttons,
  title,
  ...props
}) => {
  const CSSBlock = 'nsc-card';

  return (
    <div
      {...props}
      className={`${CSSBlock} ${props.className ? props.className : ``}`}>
      {children}
      {buttons && (
        <div className={`${CSSBlock}__action-buttons`}>{buttons}</div>
      )}
    </div>
  );
};

export default NSCCard;
