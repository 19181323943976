import React, { CSSProperties } from 'react';

import HueRing from '@components/settings/themeConfigurator/partials/HueRing';
import Slider from '@nsc-ui/Slider';

interface Screen03Props {
  isMounted: boolean;
  mountedStyle: React.CSSProperties;
  unmountedStyle: React.CSSProperties;
  // Theme config props
  hueValues: [number, number];
  setHueValues: React.Dispatch<React.SetStateAction<[number, number]>>;
  intensityValue: number;
  setIntensityValue: React.Dispatch<React.SetStateAction<number>>;
  grainValue: number;
  setGrainValue: React.Dispatch<React.SetStateAction<number>>;
}
const Screen03: React.FC<Screen03Props> = ({
  isMounted,
  mountedStyle,
  unmountedStyle,
  hueValues,
  setHueValues,
  intensityValue,
  setIntensityValue,
  grainValue,
  setGrainValue,
}) => {
  const CSSBlock = 'welcome-section';

  return (
    <div
      className={`${CSSBlock}`}
      style={isMounted ? mountedStyle : unmountedStyle}>
      <div className={`${CSSBlock}__title`}>Customise your theme</div>
      <div
        className={`${CSSBlock}__theme-colours`}
        style={
          {
            '--theme-config-hue-01': hueValues[0].toString(),
            '--theme-config-hue-02': hueValues[1].toString(),
            '--theme-config-intensity': intensityValue.toString(),
            '--theme-config-grain': grainValue.toString(),
          } as CSSProperties
        }>
        <HueRing hues={hueValues} onChange={hues => setHueValues(hues)} />
        <div className={`${CSSBlock}__sliders`}>
          <div className={`${CSSBlock}__intensity`}>
            <div className={`${CSSBlock}__subtitle`}>Intensity</div>
            <Slider
              onChange={val => setIntensityValue(val)}
              initialValue={intensityValue}
              width={250}
            />
          </div>
          <div className={`${CSSBlock}__grain`}>
            <div className={`${CSSBlock}__subtitle`}>Texture</div>
            <Slider
              onChange={val => setGrainValue(val)}
              initialValue={grainValue}
              width={250}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screen03;
