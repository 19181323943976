import React from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { useAuth } from '@providers/authProvider';

import { selectOfflineMode } from '../../../core/store/userSlice/userSlice';

const SidebarUser: React.FC = () => {
  const CSSBlock = 'sidebar-user';
  const { user } = useAuth();
  const isOfflineMode = useAppSelector(selectOfflineMode);

  return (
    <div className={CSSBlock}>
      {user ? (
        <div className={`${CSSBlock}__user-info`}>
          <div className={`${CSSBlock}__user-name`}>Hey {user.displayName}</div>
          <div className={`${CSSBlock}__user-email`}>{user.email}</div>
        </div>
      ) : (
        isOfflineMode && (
          <div className={`${CSSBlock}__local`}>Offline Mode</div>
        )
      )}
    </div>
  );
};

export default SidebarUser;
