import React, { useEffect, useRef, useState } from 'react';

import ModalDelete from '@components/modals/ModalDelete';
import ModalInput from '@components/modals/ModalInput';
import { useAppSelector } from '@core/hooks/appHooks';
import { selectOfflineMode } from '@core/store/userSlice/userSlice';
import { createRequest } from '@helpers/appHelper';
import { File_MDX, File_MDX_Tab } from '@helpers/fileHelper';
import NSCIconButton, { IconButtonSize } from '@nsc-ui/IconButton';
import Menu from '@nsc-ui/Menu/Menu';
import { MenuContent } from '@nsc-ui/Menu/MenuTypes';
import EmdeeStorage, { EmdeeStorageType } from '@storage/emdee/emdeeStorage';

enum MODALS {
  RENAME = 'rename',
  DELETE = 'delete',
}

interface Header_MDXTabProps {
  file: File_MDX;
  doReload: () => void;
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

const Header_MDXTab: React.FC<Header_MDXTabProps> = ({
  file,
  doReload,
  activeTab,
  setActiveTab,
}) => {
  if (!file) return null;

  const CSSBlock = 'header-mdx';
  const isOfflineMode = useAppSelector(selectOfflineMode);
  const storageMode = isOfflineMode
    ? EmdeeStorageType.LOCAL
    : EmdeeStorageType.FIRESTORE;
  const [activeModal, setActiveModal] = useState<undefined | MODALS>(undefined);
  const tabsRef = useRef<Array<HTMLDivElement | null>>([]);

  const menuItems: MenuContent = {
    sections: [
      {
        items: [
          {
            id: 'rename',
            faIcon: 'fa-solid fa-i-cursor',
            text: 'Rename',
          },
          {
            id: 'delete',
            faIcon: 'fa-trash',
            text: 'Delete',
            disabled: file.tabs.length === 1,
          },
        ],
      },
    ],
  };

  useEffect(() => {
    tabsRef.current[activeTab]?.scrollIntoView({ behavior: 'smooth' });
  }, [activeTab]);

  const onTabClick = async (i: number) => {
    setActiveTab(i);
    await createRequest(EmdeeStorage.MDXSetActiveTab(storageMode, file, i));
  };

  const onMenuClick = (id: string) => {
    switch (id) {
      case 'rename':
        setActiveModal(MODALS.RENAME);
        break;
      case 'delete':
        setActiveModal(MODALS.DELETE);
        break;
    }
  };

  const confirmRename = async (newName: string) => {
    await createRequest(
      EmdeeStorage.MDXRenameTab(storageMode, file, activeTab, newName)
    );
    doReload();
    setActiveModal(undefined);
  };

  const confirmDelete = async () => {
    await createRequest(
      EmdeeStorage.MDXDeleteTab(storageMode, file, activeTab)
    );
    doReload();
    setActiveModal(undefined);
  };

  return (
    <>
      {file.tabs.map((tab, i) => (
        <div
          key={i}
          ref={el => (tabsRef.current[i] = el)}
          className={`${CSSBlock}__tab ${
            activeTab === i ? `${CSSBlock}__tab--active` : ''
          }`}>
          <button
            className={`${CSSBlock}__tab-button `}
            onClick={() => onTabClick(i)}>
            {tab.name}
          </button>
          <Menu
            triggerEl={
              <NSCIconButton
                faIcon="fa-ellipsis-vertical"
                size={IconButtonSize.MINI}
              />
            }
            onClick={onMenuClick}
            content={menuItems}
          />
        </div>
      ))}

      {activeModal === MODALS.RENAME && (
        <ModalInput
          initialValue={file.tabs[activeTab].name}
          onClose={() => setActiveModal(undefined)}
          onConfirm={confirmRename}
        />
      )}
      {activeModal === MODALS.DELETE && (
        <ModalDelete
          filename={file.tabs[activeTab].name}
          onClose={() => setActiveModal(undefined)}
          onConfirm={confirmDelete}
        />
      )}
    </>
  );
};

export default Header_MDXTab;
