import { FileMetadata } from '@helpers/fileHelper';

import { LSKeys } from './localStorage';

export interface AppSnapshotData {
  activeFile: FileMetadata | undefined;
}

export const AppSnapshot = {
  set: (data: AppSnapshotData) => {
    try {
      const toStore = JSON.stringify(data);
      localStorage.setItem(LSKeys.APP_SNAPSHOT, toStore);
    } catch (e: any) {
      console.log(e);
    }
  },

  get: (): AppSnapshotData | undefined => {
    try {
      const data = localStorage.getItem(LSKeys.APP_SNAPSHOT);
      return data ? (JSON.parse(data) as AppSnapshotData) : undefined;
    } catch (e: any) {
      console.log(e);
      return undefined;
    }
  },
};
