import React from 'react';

import ThemeSwitcher from '@components/settings/themeConfigurator/partials/ThemeSwitcher';

interface Screen05Props {
  isMounted: boolean;
  mountedStyle: React.CSSProperties;
  unmountedStyle: React.CSSProperties;
}
const Screen05: React.FC<Screen05Props> = ({
  isMounted,
  mountedStyle,
  unmountedStyle,
}) => {
  const CSSBlock = 'welcome-section';

  return (
    <div
      className={`${CSSBlock}`}
      style={isMounted ? mountedStyle : unmountedStyle}>
      <div className={`${CSSBlock}__title`}>Setting everything up.</div>
      <div className={`${CSSBlock}__description`}>
        This should only take a few seconds.
        <div className={`${CSSBlock}__loading-indicator`}></div>
      </div>
    </div>
  );
};

export default Screen05;
