import React, { useState } from 'react';

import { useAppDispatch } from '@core/hooks/appHooks';
import { setFileList, setThemeConfig } from '@core/store/appSlice/appSlice';
import { useAuth } from '@providers/authProvider';
import { useFirestore } from '@providers/firestoreProvider';
import { FSIndex, FSUser } from '@storage/firebase/firestore';

interface LoadingIntroProps {
  onSuccess: () => void;
  onNewUser: () => void;
}

const LoadingAuth: React.FC<LoadingIntroProps> = ({ onSuccess, onNewUser }) => {
  const CSSBlock = 'loading-intro';
  const dispatch = useAppDispatch();
  const [hide, setHide] = useState(false);

  const onLoad = () => {
    const { user } = useAuth();
    const { usersCollection } = useFirestore();
    console.log('createCollection', usersCollection, user);
    if (!user) return;

    // Load user config into global app state.
    FSUser.getUserDoc(user).then(doc => {
      if (doc) {
        dispatch(setThemeConfig(doc.theme));
        FSIndex.getIndexDoc(user).then(async doc => {
          if (doc) dispatch(setFileList(doc));
          setTimeout(() => {
            setHide(true);
            setTimeout(() => onSuccess(), 500);
          }, 500);
        });
      } else {
        setTimeout(() => {
          setHide(true);
          setTimeout(() => onNewUser(), 500);
        }, 500);
      }
    });
  };

  onLoad();

  return (
    <div
      className={`${CSSBlock} ${CSSBlock}--auth ${
        hide ? `${CSSBlock}--hide` : ''
      }`}>
      <div className={`${CSSBlock}__background`}></div>
      <div className={`${CSSBlock}__title`}>
        e
        <span
          style={{
            fontWeight: 700,
          }}>
          md
        </span>
        ee.io
      </div>
      <div className={`${CSSBlock}__subtitle`}>Loading User Data</div>
      <div className={`${CSSBlock}__loading-indicator`}></div>
    </div>
  );
};

export default LoadingAuth;
