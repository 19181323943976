import React, { useEffect, useState } from 'react';

import Authentication from '@components/authentication/Authentication';
import LoadingAuth from '@components/loadingIntro/LoadingAuth';
import LoadingGlobal from '@components/loadingIntro/LoadingGlobal';
import LoadingIntro from '@components/loadingIntro/LoadingIntro';
import Welcome from '@components/welcome/Welcome';
import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { getFileIndex } from '@helpers/fileHelper';
import { getSettings } from '@helpers/settingsHelper';
import { ThemeConfig } from '@helpers/themeHelper';
import { useDelayUnmount } from '@hooks/useDelayUnmount';
import { useAuth } from '@providers/authProvider';
import { LSKeys, LocalStorage } from '@storage/localStorage/localStorage';

import Home from '../components/Home';
import {
  selectIsLoading,
  setFileList,
  setThemeConfig,
} from './store/appSlice/appSlice';
import { setSettings } from './store/settingsSlice/settingsSlice';
import { selectOfflineMode, setOfflineMode } from './store/userSlice/userSlice';
import AppTheme from './theme/AppTheme';

const AppCore: React.FC = () => {
  const isOfflineMode = useAppSelector(selectOfflineMode);
  const [isLoadingIntro, setIsLoadingIntro] = useState(true);
  const [isLoadingContent, setIsLoadingContent] = useState(false);
  const dispatch = useAppDispatch();
  const { user } = useAuth();
  const isLoading = useAppSelector(selectIsLoading);

  /**
   * For transitioning components
   */
  // Welcome
  const [isWelcomeMounted, setIsWelcomeMounted] = useState(false);
  const shouldRenderWelcome = useDelayUnmount(isWelcomeMounted, 200);
  // Home
  const [isHomeMounted, setIsHomeMounted] = useState(false);
  const shouldRenderHome = useDelayUnmount(isHomeMounted, 200);
  // Authentication
  const [isAuthenticationMounted, setIsAuthenticationMounted] = useState(true);
  const shouldRenderAuthentication = useDelayUnmount(
    isAuthenticationMounted,
    200
  );
  // Global loading indicator
  const [isGlobalLoadingMounted, setIsGlobalLoadingMounted] = useState(false);
  const shouldRenderGlobalLoading = useDelayUnmount(
    isGlobalLoadingMounted,
    200
  );

  /**
   * Firebase auth
   */
  useEffect(() => {
    if (!!user) {
      // if authenticated with FS
      setIsAuthenticationMounted(false);
      loadUserData().then(hasData => {
        if (hasData) {
          setIsHomeMounted(true);
          setIsWelcomeMounted(false);
        } else {
          setIsWelcomeMounted(true);
          setIsHomeMounted(false);
        }
      });
    } else if (!isOfflineMode) {
      // if not authenticated and not LS.
      setIsAuthenticationMounted(true);
      setIsHomeMounted(false);
      setIsWelcomeMounted(false);
    }
  }, [user]);

  /**
   * Local storage
   */
  useEffect(() => {
    if (isOfflineMode === true) useLS();
    else if (!user) {
      setIsHomeMounted(false);
      setIsWelcomeMounted(false);
      setIsAuthenticationMounted(true);
    }
  }, [isOfflineMode]);

  const useLS = () => {
    const config = LocalStorage.get(LSKeys.THEME_CONFIG) as ThemeConfig;
    dispatch(setThemeConfig(config));
  };

  const onClickOfflineMode = () => {
    dispatch(setOfflineMode(true));
    setIsAuthenticationMounted(false);
    loadUserData(true).then(hasData => {
      if (hasData) {
        setIsHomeMounted(true);
        setIsWelcomeMounted(false);
      } else {
        setIsWelcomeMounted(true);
        setIsHomeMounted(false);
      }
    });
  };

  /**
   * Load user data
   */
  const loadUserData = (offlineMode: boolean = false): Promise<boolean> =>
    new Promise<any>(resolve => {
      // Load file list
      Promise.all([
        getSettings(offlineMode, user),
        getFileIndex(offlineMode, user),
      ]).then(([settings, files]) => {
        console.log('loadUserData', files, settings);
        if (settings || files) {
          if (settings) dispatch(setSettings(settings));
          if (files) dispatch(setFileList(files));
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });

  const onWelcomeComplete = () => {
    setIsWelcomeMounted(false);
    setIsHomeMounted(true);
  };

  useEffect(() => {
    setIsGlobalLoadingMounted(isLoading);
  }, [isLoading]);

  return (
    <>
      <AppTheme isHomeActive={isHomeMounted} />

      {/* Main editor */}
      {shouldRenderHome && <Home isMounted={isHomeMounted} />}

      {/* New user welcome screen */}
      {shouldRenderWelcome && (
        <Welcome isMounted={isWelcomeMounted} onComplete={onWelcomeComplete} />
      )}

      {/* Authentication Modal */}
      {shouldRenderAuthentication && (
        <Authentication
          onClickOfflineMode={onClickOfflineMode}
          isMounted={isAuthenticationMounted}
        />
      )}

      {/* Loading user data if authenticated */}
      {user && isLoadingContent && (
        <LoadingAuth
          onSuccess={() => setIsLoadingContent(false)}
          onNewUser={() => {
            setIsLoadingContent(false);
          }}
        />
      )}

      {/* Main app loading */}
      {isLoadingIntro && (
        <LoadingIntro
          onAnimStart={() => setIsLoadingContent(true)}
          onAnimEnd={() => setIsLoadingIntro(false)}
        />
      )}

      {shouldRenderGlobalLoading && (
        <LoadingGlobal isMounted={isGlobalLoadingMounted} />
      )}
    </>
  );
};

export default AppCore;
