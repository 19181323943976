import { File, FileMetadata } from '@helpers/fileHelper';
import { ThemeConfig, defaultThemeConfig } from '@helpers/themeHelper';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { emdeeFilesIndex } from '@storage/emdee/_common';
import { AppSnapshotData } from '@storage/localStorage/appSnapshot';
import { LSKeys, LocalStorage } from '@storage/localStorage/localStorage';

import { RootState } from '../store';

interface AppState {
  theme: ThemeConfig;
  openTabs: File[]; // change to array of id numbers
  fileList: emdeeFilesIndex;
  activeFile: FileMetadata | undefined; // change to id of file
  activeRequests: string[];
}

const initialState: AppState = {
  theme: defaultThemeConfig,
  openTabs: [],
  fileList: { content: [] },
  activeFile: (LocalStorage.get(LSKeys.APP_SNAPSHOT) as AppSnapshotData)
    ?.activeFile,
  activeRequests: [],
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    pushNetworkRequestUUID: (
      state,
      { payload: uuid }: PayloadAction<string>
    ) => {
      state.activeRequests = [...state.activeRequests, uuid];
    },

    popNetworkRequestUUID: (
      state,
      { payload: uuid }: PayloadAction<string>
    ) => {
      state.activeRequests = state.activeRequests.filter(
        request => request !== uuid
      );
    },

    /**
     *
     */
    setThemeConfig: (state, action: PayloadAction<ThemeConfig>) => {
      state.theme = action.payload;
    },

    // File handling

    /**
     * Get list to populate the sidebar's file browser.
     */
    setFileList: (state, action: PayloadAction<emdeeFilesIndex>) => {
      state.fileList = action.payload;
    },

    /**
     *
     */
    setActiveFile: (state, action: PayloadAction<FileMetadata | undefined>) => {
      state.activeFile = action.payload;
      LocalStorage.set(LSKeys.APP_SNAPSHOT, { activeFile: action.payload });
    },
  },
});

export const {
  setFileList,
  setActiveFile,
  setThemeConfig,
  pushNetworkRequestUUID,
  popNetworkRequestUUID,
} = appSlice.actions;

export const selectThemeConfig = (state: RootState) => state.app.theme;
export const selectOpenTabs = (state: RootState) => state.app.openTabs;
export const selectFileList = (state: RootState) => state.app.fileList;
export const selectActiveFile = (state: RootState) => state.app.activeFile;
export const selectIsLoading = (state: RootState) =>
  state.app.activeRequests.length > 0;
export const selectActiveRequests = (state: RootState) =>
  state.app.activeRequests;

export default appSlice.reducer;
