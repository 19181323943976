import React from 'react';

import ThemeSwitcher from '@components/settings/themeConfigurator/partials/ThemeSwitcher';

interface Screen02Props {
  isMounted: boolean;
  mountedStyle: React.CSSProperties;
  unmountedStyle: React.CSSProperties;
}
const Screen02: React.FC<Screen02Props> = ({
  isMounted,
  mountedStyle,
  unmountedStyle,
}) => {
  const CSSBlock = 'welcome-section';

  return (
    <div
      className={`${CSSBlock}`}
      style={isMounted ? mountedStyle : unmountedStyle}>
      <div className={`${CSSBlock}__title`}>Select theme mode</div>
      <div className={`${CSSBlock}__description`}>
        <ThemeSwitcher />
      </div>
    </div>
  );
};

export default Screen02;
