import { FileMetadata, Folder, isFile, isFolder } from '@helpers/fileHelper';

export const FilePrefix = 'emdee.iofile#';
export const FolderPrefix = 'emdee.iofolder#';
export const getFileStorageKey = (uuid: string) => `${FilePrefix}${uuid}`;
export const getFolderStorageKey = (uuid: string) => `${FilePrefix}${uuid}`;

export interface emdeeFilesIndex {
  content: (FileMetadata | Folder)[];
}
export function isEmdeeFilesIndex(T: any): T is emdeeFilesIndex {
  return (T as emdeeFilesIndex).content !== undefined;
}

export const recursiveRenameFileInIndex = (
  content: (FileMetadata | Folder)[],
  uuid: string,
  newName: string
): (FileMetadata | Folder)[] => {
  return content.map((item: FileMetadata | Folder) =>
    isFile(item)
      ? item.uuid === uuid
        ? ({ ...item, name: newName } as FileMetadata)
        : (item as FileMetadata)
      : isFolder(item)
      ? ({
          ...item,
          items: recursiveRenameFileInIndex(item.items, uuid, newName),
        } as Folder)
      : (item as any)
  );
};

export const recursiveDeleteFileInIndex = (
  content: (FileMetadata | Folder)[],
  fileUUID: string
): (FileMetadata | Folder)[] => {
  return content.filter((item: FileMetadata | Folder) => {
    if (isFile(item)) {
      return item.uuid !== fileUUID;
    } else if (isFolder(item)) {
      item.items = recursiveDeleteFileInIndex(item.items, fileUUID);
    }
    return true;
  });
};
