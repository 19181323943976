import React from 'react';

interface Screen04Props {
  isMounted: boolean;
  mountedStyle: React.CSSProperties;
  unmountedStyle: React.CSSProperties;
}
const Screen04: React.FC<Screen04Props> = ({
  isMounted,
  mountedStyle,
  unmountedStyle,
}) => {
  const CSSBlock = 'welcome-section';

  return (
    <div
      className={`${CSSBlock}`}
      style={isMounted ? mountedStyle : unmountedStyle}>
      <div className={`${CSSBlock}__title`}>You're all set!</div>
      <div className={`${CSSBlock}__description`}>
        You seem to be new here.
        <br />
        Let's help you get started.
      </div>
    </div>
  );
};

export default Screen04;
