import React from 'react';

import { File_MD } from '@helpers/fileHelper';
import { JSONContent } from '@tiptap/react';

import Tiptap from './TipTap';
import Header_MD from './_Header_MD';

interface Page_MDProps {
  CSSBlock: string;
  file: File_MD;
  onUpdate: (content: JSONContent) => void;
}

const Page_MD: React.FC<Page_MDProps> = ({ CSSBlock, file, onUpdate }) => {
  return (
    <>
      <Header_MD />
      <div className={`${CSSBlock}__background`}>
        <div className={`${CSSBlock}__content`}>
          {file && (
            <Tiptap content={(file as File_MD).content} onUpdate={onUpdate} />
          )}
        </div>
      </div>
    </>
  );
};

export default Page_MD;
