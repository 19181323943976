import React, { useState } from 'react';

import ModalInput from '@components/modals/ModalInput';
import { useAppSelector } from '@core/hooks/appHooks';
import { selectOfflineMode } from '@core/store/userSlice/userSlice';
import { createRequest } from '@helpers/appHelper';
import { File_MDX } from '@helpers/fileHelper';
import NSCIconButton, { IconButtonSize } from '@nsc-ui/IconButton';
import EmdeeStorage, { EmdeeStorageType } from '@storage/emdee/emdeeStorage';

import Header_MDXTab from './_Header_MDXTab';

enum MODALS {
  NEW_TAB = 'new-tab',
}

interface Page_MDXProps {
  file: File_MDX;
  doReload: () => void;
  activeTab: number;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}

const Header_MDX: React.FC<Page_MDXProps> = ({
  file,
  doReload,
  activeTab,
  setActiveTab,
}) => {
  if (!file) return null;

  const CSSBlock = 'header-mdx';
  const isOfflineMode = useAppSelector(selectOfflineMode);
  const storageMode = isOfflineMode
    ? EmdeeStorageType.LOCAL
    : EmdeeStorageType.FIRESTORE;
  const [activeModal, setActiveModal] = useState<undefined | MODALS>(undefined);

  const onCreateTab = async (name: string) => {
    await createRequest(EmdeeStorage.MDXCreateTab(storageMode, file, name));
    setActiveModal(undefined);
    doReload();
  };

  return (
    <>
      <div className={CSSBlock}>
        <div className={`${CSSBlock}__tabs`}>
          <Header_MDXTab
            file={file}
            doReload={doReload}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </div>
        <div className={`${CSSBlock}__create-tab`}>
          <NSCIconButton
            size={IconButtonSize.SMALL}
            faIcon="fa-plus"
            onClick={() => setActiveModal(MODALS.NEW_TAB)}
          />
        </div>
      </div>

      {activeModal === MODALS.NEW_TAB && (
        <ModalInput
          placeholder="Name your tab"
          onClose={() => setActiveModal(undefined)}
          onConfirm={onCreateTab}
        />
      )}
    </>
  );
};

export default Header_MDX;
