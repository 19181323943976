import React, { useEffect } from 'react';

import Page from '@components/page/Page';
import { selectAllSettings } from '@core/store/settingsSlice/settingsSlice';

import { useAppSelector } from '../core/hooks/appHooks';
import Toolbar from './toolbar/Toolbar';
import ToolbarFloating from './toolbar/ToolbarFloating';

const Editor: React.FC = () => {
  const CSSBlock = 'editor';
  const appSettings = useAppSelector(selectAllSettings);

  useEffect(() => {
    console.log('appSettings', appSettings);
  }, [appSettings]);

  return (
    <div
      className={`${CSSBlock} ${
        appSettings.editor.showToolbar === false
          ? `${CSSBlock}--no-toolbar`
          : ''
      } ${
        appSettings.editor.showMarkdownHints === false
          ? `${CSSBlock}--no-hints`
          : ''
      }`}>
      <Page />
      {appSettings.editor.showToolbar ? <Toolbar /> : <ToolbarFloating />}
    </div>
  );
};

export default Editor;
