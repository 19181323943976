import { User } from 'firebase/auth';

import { setThemeConfig } from '@core/store/appSlice/appSlice';
import { ThunkDispatch } from '@reduxjs/toolkit';
import FSUser from '@storage/firebase/_users';
import { LSKeys, LocalStorage } from '@storage/localStorage/localStorage';

export enum ThemeModeConstants {
  LIGHT = 'light',
  DARK = 'dark',
  SYSTEM = 'system',
}

export interface ThemeConfig {
  hue: [number, number];
  intensity: number;
  grain: number;
  mode: ThemeModeConstants;
}
export const defaultThemeConfig: ThemeConfig = {
  hue: [200, 160],
  intensity: 0.6,
  grain: 0.4,
  mode: ThemeModeConstants.SYSTEM,
};

export type ThemeMode =
  | ThemeModeConstants.LIGHT
  | ThemeModeConstants.DARK
  | ThemeModeConstants.SYSTEM;

export enum CSSVARS {
  THEME_BG_POS = '--theme-bg-pos',
  THEME_BG_HUE_01 = '--theme-bg-hue-01',
  THEME_BG_HUE_02 = '--theme-bg-hue-02',
  THEME_BG_INTENSITY = '--theme-bg-intensity',
  THEME_BG_GRAIN = '--theme-bg-grain',
  // Just for previewing the theme
  THEME_CONFIG_HUE_01 = '--theme-config-hue-01',
  THEME_CONFIG_HUE_02 = '--theme-config-hue-02',
  THEME_CONFIG_INTENSITY = '--theme-config-intensity',
  THEME_CONFIG_GRAIN = '--theme-config-grain',
}

export const setAppBackground = (theme: ThemeConfig) => {
  setCSSVar(CSSVARS.THEME_BG_HUE_01, theme.hue[0]);
  setCSSVar(CSSVARS.THEME_BG_HUE_02, theme.hue[1]);
  setCSSVar(CSSVARS.THEME_BG_INTENSITY, theme.intensity);
  setCSSVar(CSSVARS.THEME_BG_GRAIN, theme.grain);
};

export const setCSSVar = (cssVar: CSSVARS, value: number | string) =>
  document.documentElement.style.setProperty(cssVar, value.toString());

export const isDarkMode = (themeMode: ThemeMode) =>
  themeMode === ThemeModeConstants.SYSTEM
    ? window.matchMedia('(prefers-color-scheme: dark)').matches
    : themeMode === ThemeModeConstants.DARK;

/**
 *
 * @param dispatch
 * @param settings
 */
export const setTheme = async ({
  dispatch,
  theme,
  isOfflineMode,
  user,
}: {
  dispatch: ThunkDispatch<any, any, any>;
  theme: ThemeConfig;
  isOfflineMode: boolean;
  user?: User | null;
}) => {
  // Save in store
  dispatch(setThemeConfig(theme));
  setAppBackground(theme);

  // Save in user
  if (!isOfflineMode && !user) return;
  return isOfflineMode
    ? LocalStorage.set(LSKeys.THEME_CONFIG, theme)
    : await FSUser.updateUserTheme(user!, theme);
};
