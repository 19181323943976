import React, {
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import ReactPortal from './ReactPortal';

const CSSBlock = 'nsc-modal';

export type NSCModalCoreRef = {
  gracefulClose: (postExit: () => void) => void;
};

interface NSCModalCoreProps extends PropsWithChildren {
  isOpen: boolean;
  onExited: () => void;
  allowBackdropClose?: boolean;
}

const NSCModalCore = React.forwardRef<NSCModalCoreRef, NSCModalCoreProps>(
  ({ children, isOpen, onExited, allowBackdropClose = true }, ref) => {
    if (!isOpen) return null;
    const [modalClass, setModalClass] = useState('');

    useImperativeHandle(ref, () => ({
      gracefulClose(postExit: () => void) {
        setModalClass('');
        setTimeout(() => postExit(), 300);
      },
    }));

    const onClose = () => {
      setModalClass('');
      setTimeout(() => onExited(), 300);
    };

    useEffect(() => {
      requestAnimationFrame(() => setModalClass(`${CSSBlock}--visible`));
    }, []);

    useEffect(() => {
      const onKeyDown = (e: globalThis.KeyboardEvent) => {
        if (e.key === 'Escape') onClose();
      };
      document.addEventListener('keydown', onKeyDown);
      return () => document.removeEventListener('keydown', onKeyDown);
    }, []);

    return (
      <ReactPortal wrapperId="nsc-modal-something">
        <div className={`${CSSBlock} ${modalClass}`}>
          <div
            className={`${CSSBlock}__backdrop`}
            onClick={() => {
              if (allowBackdropClose) onClose();
            }}></div>
          <div className={`${CSSBlock}__content`}>{children}</div>
        </div>
      </ReactPortal>
    );
  }
);

export default NSCModalCore;
