import React, { CSSProperties, useEffect, useState } from 'react';

import useDebounce from '@core/hooks/useDebounce';
import { CSSVARS } from '@helpers/themeHelper';
import Wheel from '@uiw/react-color-wheel';
import { PointerProps } from '@uiw/react-color-wheel/cjs/Pointer';

import HueButton from './HueButton';

const Pointer = ({
  className,
  color,
  left,
  top,
  style,
  prefixCls,
}: PointerProps) => (
  <div
    className={`${CSSBlock}__picker-indicator`}
    style={{ ...style, backgroundColor: color }}></div>
);

const CSSBlock = 'theme-configurator';

interface HueRingProps {
  hues: [number, number];
  onChange: (hue: [number, number]) => void;
}

const HueRing: React.FC<HueRingProps> = ({ hues, onChange }) => {
  const [activeHue, setActiveHue] = useState(0);
  const [hueValues, setHueValues] = useState<[number, number]>(hues);
  const debouncedValue = useDebounce<[number, number]>(hueValues, 500);

  useEffect(() => {
    onChange(hueValues);
  }, [debouncedValue]);

  return (
    <div
      className={`${CSSBlock}__hue`}
      style={
        {
          '--theme-config-hue-01': hueValues[0].toString(),
          '--theme-config-hue-02': hueValues[1].toString(),
        } as CSSProperties
      }>
      <div className={`${CSSBlock}__active-hue-selector`}>
        <HueButton
          text="Primary"
          hueVariable={CSSVARS.THEME_CONFIG_HUE_01}
          active={activeHue === 0}
          onClick={() => setActiveHue(0)}
        />
        <HueButton
          text="Secondary"
          hueVariable={CSSVARS.THEME_CONFIG_HUE_02}
          active={activeHue === 1}
          onClick={() => setActiveHue(1)}
        />
      </div>
      <div className={`${CSSBlock}__picker`}>
        <div className={`${CSSBlock}__picker-ring`}>
          <Wheel
            color={{ h: hueValues[activeHue], s: 97, v: 90, a: 1 }}
            width={240}
            height={240}
            pointer={Pointer}
            onChange={color => {
              const updated: [number, number] = [...hueValues];
              updated[activeHue] = color.hsla.h;
              setHueValues(updated);
            }}
          />
        </div>
        <div className={`${CSSBlock}__picker-preview`}>
          <div className={`${CSSBlock}__picker-preview-left`}></div>
          <div className={`${CSSBlock}__picker-preview-main`}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className={`${CSSBlock}__picker-preview-toolbar`}></div>
        </div>
      </div>
    </div>
  );
};

export default HueRing;
