import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'remixicon/fonts/remixicon.css';

import '@assets/fa-all.min.js';
import AppCore from '@core/AppCore';
import { store } from '@core/store/store';
import { AuthProvider } from '@providers/authProvider';
import { FirestoreProvider } from '@providers/firestoreProvider';
import '@styles/index.scss';

const root = createRoot(document.getElementById('md-notepad')!);
root.render(
  <Provider store={store}>
    <AuthProvider>
      <FirestoreProvider>
        <AppCore />
      </FirestoreProvider>
    </AuthProvider>
  </Provider>
);
