import { Folder } from '@helpers/fileHelper';

import LSIndex from './_index';

/**
 *
 */
export default class LSFolder {
  /**
   * Creates and saves to index.
   * @param name
   * @returns
   */
  static create(name: string): Folder {
    const folder = {
      uuid: crypto.randomUUID(),
      name,
      items: [],
    };
    const index = LSIndex.getIndex();
    LSIndex.set(
      index
        ? { ...index, content: [...index.content, folder] }
        : { content: [folder] }
    );
    return folder;
  }
}
