import React from 'react';

import * as MenuTypes from './MenuTypes';

interface MenuItemProps {
  item: MenuTypes.MenuItem;
  onClick: (id: string) => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ item, onClick }) => {
  const CSSBlock = 'nsc-menu-item';
  const faStyles = [
    'fa-solid',
    'fa-regular',
    'fa-light',
    'fa-thin',
    'fa-duotone',
  ];

  return (
    <button
      className={CSSBlock}
      onClick={() => onClick(item.id)}
      disabled={item.disabled}>
      <span className={`${CSSBlock}__icon`}>
        <i
          className={
            faStyles.find(fa => item.faIcon.includes(fa))
              ? item.faIcon
              : `fa-solid ${item.faIcon}`
          }></i>
      </span>
      <span className={`${CSSBlock}__text`}>{item.text}</span>
    </button>
  );
};

export default MenuItem;
