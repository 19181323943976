import { User } from 'firebase/auth';

import {
  File,
  FileTypes,
  File_MD,
  File_MDX,
  Folder,
} from '@helpers/fileHelper';
import emdeeFileMD from '@storage/emdee/_fileMD';
import emdeeFileMDX from '@storage/emdee/_fileMDX';
import FSFileMD from '@storage/firebase/_fileMD';
import FSFileMDX from '@storage/firebase/_fileMDX';
import FSFiles from '@storage/firebase/_files';
import FSFolder from '@storage/firebase/_folder';
import FSIndex from '@storage/firebase/_index';
import LSFiles from '@storage/localStorage/_file';
import LSFileMD from '@storage/localStorage/_fileMD';
import LSFileMDX from '@storage/localStorage/_fileMDX';
import LSIndex from '@storage/localStorage/_index';
import { LSFolder } from '@storage/localStorage/files';
import { JSONContent } from '@tiptap/core';

import { emdeeFilesIndex } from './_common';

export enum EmdeeStorageType {
  LOCAL = 'local-storage',
  FIRESTORE = 'firestore',
}

export default class EmdeeStorage {
  /**
   *
   * @param storage
   * @param param1
   * @param user
   */
  static async createFile(
    storage: EmdeeStorageType,
    {
      name,
      type,
      folderUUID,
      tabName,
      content,
    }: {
      name: string;
      type: FileTypes;
      folderUUID?: string;
      tabName?: string;
      content?: JSONContent;
    },
    user?: User | null
  ): Promise<File | undefined> {
    if (type === FileTypes.COLLECTION && !tabName) return;
    if (storage === EmdeeStorageType.FIRESTORE && !user) return;

    // Create
    const file =
      type === FileTypes.COLLECTION
        ? emdeeFileMDX.create(name, tabName!)
        : emdeeFileMD.create(name, content);
    // Save
    storage === EmdeeStorageType.LOCAL
      ? LSFiles.save(file, folderUUID)
      : await FSFiles.saveFile(user!, file, folderUUID);

    return file;
  }

  /**
   *
   * @param storage
   * @param fileUUID
   */
  static async loadFile(
    storage: EmdeeStorageType,
    fileUUID: string
  ): Promise<File | undefined> {
    return storage === EmdeeStorageType.FIRESTORE
      ? await FSFiles.getFileDoc(fileUUID)
      : LSFiles.load(fileUUID);
  }

  /**
   *
   * @param storage
   * @param file
   * @param content
   * @param tab
   */
  static async updateFile(
    storage: EmdeeStorageType,
    file: File,
    content: JSONContent,
    tab?: number
  ) {
    if (file.type === FileTypes.COLLECTION && tab === undefined) return;
    return file.type === FileTypes.COLLECTION
      ? EmdeeStorage.updateFileMDX(storage, file as File_MDX, tab!, content)
      : EmdeeStorage.updateFileMD(storage, file as File_MD, content);
  }

  /**
   *
   * @param storage
   * @param file
   * @param content
   */
  static async updateFileMD(
    storage: EmdeeStorageType,
    file: File_MD,
    content: JSONContent
  ) {
    return storage === EmdeeStorageType.FIRESTORE
      ? await FSFileMD.updateContent(file, content)
      : LSFileMD.updateContent(file, content);
  }

  /**
   *
   * @param storage
   * @param file
   * @param tab
   * @param content
   */
  static async updateFileMDX(
    storage: EmdeeStorageType,
    file: File_MDX,
    tab: number,
    content: JSONContent
  ) {
    return storage === EmdeeStorageType.FIRESTORE
      ? await FSFileMDX.updateContent(file, tab, content)
      : LSFileMDX.updateContent(file, tab, content);
  }

  /**
   *
   * @param storage
   * @param file
   * @param newName
   * @param user
   */
  static async renameFile(
    storage: EmdeeStorageType,
    file: File,
    newName: string,
    user?: User | null
  ) {
    if (storage === EmdeeStorageType.FIRESTORE && !user) return;
    return storage === EmdeeStorageType.FIRESTORE
      ? await FSFiles.renameFile(user!, file, newName)
      : LSFiles.rename(file, newName);
  }

  /**
   *
   * @param storage
   * @param file
   * @param targetFoler
   * @param user
   */
  static async moveFile(
    storage: EmdeeStorageType,
    file: File,
    targetFoler: Folder,
    user?: User | null
  ) {
    if (storage === EmdeeStorageType.FIRESTORE && !user) return;
    return storage === EmdeeStorageType.FIRESTORE
      ? await FSFiles.moveFile(user!, file, targetFoler)
      : LSFiles.moveToFolder(file, targetFoler);
  }

  /**
   *
   * @param storage
   * @param file
   * @param user
   */
  static async deleteFile(
    storage: EmdeeStorageType,
    file: File,
    user?: User | null
  ) {
    if (storage === EmdeeStorageType.FIRESTORE && !user) return;
    return storage === EmdeeStorageType.FIRESTORE
      ? await FSFiles.deleteFile(user!, file)
      : LSFiles.delete(file);
  }

  static async MDXCreateTab(
    storage: EmdeeStorageType,
    file: File,
    tabName: string
  ) {
    return storage === EmdeeStorageType.FIRESTORE
      ? await FSFileMDX.createTab(file, tabName)
      : LSFileMDX.createTab(file, tabName);
  }

  static async MDXRenameTab(
    storage: EmdeeStorageType,
    file: File_MDX,
    tab: number,
    name: string
  ) {
    return storage === EmdeeStorageType.FIRESTORE
      ? await FSFileMDX.renameTab(file, tab, name)
      : LSFileMDX.renameTab(file, tab, name);
  }

  static async MDXDeleteTab(
    storage: EmdeeStorageType,
    file: File_MDX,
    tab: number
  ) {
    return storage === EmdeeStorageType.FIRESTORE
      ? await FSFileMDX.deleteTab(file, tab)
      : LSFileMDX.deleteTab(file, tab);
  }

  static async MDXSetActiveTab(
    storage: EmdeeStorageType,
    file: File_MDX,
    tab: number
  ) {
    return storage === EmdeeStorageType.FIRESTORE
      ? await FSFileMDX.setActiveTab(file, tab)
      : LSFileMDX.setActiveTab(file, tab);
  }

  /**
   *
   * @param storage
   * @param name
   * @param user
   */
  static async createFolder(
    storage: EmdeeStorageType,
    name: string,
    user?: User | null
  ): Promise<Folder | undefined> {
    if (storage === EmdeeStorageType.FIRESTORE && !user) return;
    return storage === EmdeeStorageType.LOCAL
      ? LSFolder.create(name)
      : await FSFolder.create(user!, name);
  }

  /**
   *
   * @param storage
   * @param user
   */
  static async getIndex(
    storage: EmdeeStorageType,
    user?: User | null
  ): Promise<emdeeFilesIndex | undefined> {
    console.log('getIndex', storage, user);
    if (storage === EmdeeStorageType.FIRESTORE && !user) return;

    return storage === EmdeeStorageType.FIRESTORE
      ? await FSIndex.getIndexDoc(user!)
      : LSIndex.getIndex();
  }
}
