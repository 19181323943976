import React, { useEffect, useState } from 'react';

import ModalDelete from '@components/modals/ModalDelete';
import ModalInput from '@components/modals/ModalInput';
import ModalMoveFile from '@components/modals/ModalMoveFile';
import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import { selectActiveFile, setActiveFile } from '@core/store/appSlice/appSlice';
import { selectOfflineMode } from '@core/store/userSlice/userSlice';
import { createRequest } from '@helpers/appHelper';
import {
  File,
  FileMetadata,
  Folder,
  getFileFromFileMetadata,
  refreshFileList,
} from '@helpers/fileHelper';
import NSCIconButton, { IconButtonSize } from '@nsc-ui/IconButton';
import Menu from '@nsc-ui/Menu/Menu';
import { MenuContent } from '@nsc-ui/Menu/MenuTypes';
import { useAuth } from '@providers/authProvider';
import EmdeeStorage, { EmdeeStorageType } from '@storage/emdee/emdeeStorage';

const menuItems: MenuContent = {
  sections: [
    {
      items: [
        {
          id: 'rename',
          faIcon: 'fa-solid fa-i-cursor',
          text: 'Rename',
        },
        {
          id: 'move',
          faIcon: 'fa-folder',
          text: 'Move to Folder',
        },
        { id: 'delete', faIcon: 'fa-trash', text: 'Delete' },
      ],
    },
  ],
};

enum MODALS {
  RENAME = 'rename',
  MOVE = 'move',
  DELETE = 'delete',
}

interface SidebarFileProps {
  metadata: FileMetadata;
  tabIndex?: number;
}

const SidebarFile: React.FC<SidebarFileProps> = ({ metadata, tabIndex }) => {
  const CSSBlock = 'file';
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const isOfflineMode = useAppSelector(selectOfflineMode);
  const storageMode = isOfflineMode
    ? EmdeeStorageType.LOCAL
    : EmdeeStorageType.FIRESTORE;

  const activeTab =
    useAppSelector(selectActiveFile) ||
    ({
      uuid: '',
      name: '',
    } as FileMetadata);
  const [filename, setFilename] = useState<string>('');
  const [fileExtension, setFileExtension] = useState<string>('-');
  const [activeModal, setActiveModal] = useState<undefined | MODALS>(undefined);

  let file: File;
  getFileFromFileMetadata(storageMode, metadata).then(item => {
    if (item) file = item;
  });

  const onClick = () => dispatch(setActiveFile(metadata));

  useEffect(() => {
    setFilename(metadata.name);
    setFileExtension(metadata.type);
  }, [metadata]);

  const onMenuClick = (id: string) => {
    switch (id) {
      case 'rename':
        setActiveModal(MODALS.RENAME);
        break;
      case 'move':
        setActiveModal(MODALS.MOVE);
        break;
      case 'delete':
        setActiveModal(MODALS.DELETE);
        break;
    }
  };

  const confirmRename = async (newName: string) => {
    if (file) {
      await createRequest(
        EmdeeStorage.renameFile(storageMode, file, newName, user)
      );
      await createRequest(
        refreshFileList(dispatch, storageMode, user, activeTab)
      );
    }
    setActiveModal(undefined);
  };

  const confirmMove = async (targetFolder: Folder) => {
    if (file) {
      await createRequest(
        EmdeeStorage.moveFile(storageMode, file, targetFolder, user)
      );
      await createRequest(
        refreshFileList(dispatch, storageMode, user, activeTab)
      );
    }
    setActiveModal(undefined);
  };

  const confirmDelete = async () => {
    if (file) {
      await createRequest(EmdeeStorage.deleteFile(storageMode, file, user));
      await createRequest(
        refreshFileList(dispatch, storageMode, user, activeTab)
      );
    }
    setActiveModal(undefined);
  };

  // If renaming active tab, update active tab in state.
  // That should update the Active Tabs section and name in header

  return (
    <div
      className={`${CSSBlock} ${
        metadata.uuid === activeTab.uuid ? `${CSSBlock}--active` : ''
      }`}>
      <button
        className={`${CSSBlock}__button `}
        onClick={onClick}
        {...(tabIndex ? { tabIndex: tabIndex } : {})}>
        <span className={`${CSSBlock}__type-icon`}>
          <i className="fa-regular fa-file"></i>
        </span>
        {filename}
        <span className={`${CSSBlock}__extension-icon`}>{fileExtension}</span>
      </button>
      <Menu
        triggerEl={
          <NSCIconButton
            faIcon="fa-ellipsis-vertical"
            size={IconButtonSize.MINI}
            tabIndex={tabIndex}
          />
        }
        onClick={onMenuClick}
        content={menuItems}
      />
      {activeModal === MODALS.RENAME && (
        <ModalInput
          initialValue={metadata.name}
          onClose={() => setActiveModal(undefined)}
          onConfirm={confirmRename}
        />
      )}
      {activeModal === MODALS.MOVE && (
        <ModalMoveFile
          onClose={() => setActiveModal(undefined)}
          onConfirm={confirmMove}
        />
      )}
      {activeModal === MODALS.DELETE && (
        <ModalDelete
          filename={metadata.name}
          onClose={() => setActiveModal(undefined)}
          onConfirm={confirmDelete}
        />
      )}
    </div>
  );
};

export default SidebarFile;
