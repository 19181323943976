import React, { useEffect, useState } from 'react';

import { Capacitor } from '@capacitor/core';
import { useAppSelector } from '@core/hooks/appHooks';
import {
  CSSVARS,
  ThemeConfig,
  isDarkMode,
  setAppBackground,
  setCSSVar,
} from '@helpers/themeHelper';

import { selectThemeConfig } from '../store/appSlice/appSlice';
import AppThemeHue from './AppThemeHue';

// If a mobile device via a web browser
const isMobileWeb = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

// If installed as an app on mobiles
const isCapacitor = () => Capacitor.isNativePlatform();

const setBodyClass = (isDarkMode: boolean) =>
  document.body.classList.toggle(`theme-dark`, isDarkMode);

const setTheme = (theme: ThemeConfig) => {
  setAppBackground(theme);
  setBodyClass(isDarkMode(theme.mode));
};

const fps = 60;
// const duration = 20;

interface AppThemeProps {
  isHomeActive: boolean;
}

const AppTheme: React.FC<AppThemeProps> = ({ isHomeActive }) => {
  const themeConfig = useAppSelector(selectThemeConfig);
  document.body.classList.toggle('isMobile', !!isMobileWeb());
  document.body.classList.toggle('isCapacitor', !!isCapacitor());

  const [interval, setInterval] = useState<number>(0);

  useEffect(() => {
    setTheme(themeConfig);
  }, [themeConfig]);

  let hue = Math.round(Math.random() * 360);
  const hueRotationDuration = 10; // seconds

  const animate = () => {
    requestAnimationFrame(() => {
      hue += (1 * 360) / fps / hueRotationDuration;
      setCSSVar(CSSVARS.THEME_BG_HUE_01, hue.toString());
      setCSSVar(CSSVARS.THEME_BG_HUE_02, (hue + 80).toString());
    });
  };

  useEffect(() => {
    clearInterval(interval);
    !isHomeActive
      ? setInterval(window.setInterval(animate, 1000 / fps))
      : clearInterval(interval);
  }, [isHomeActive]);

  return (
    <div className="app-theme" style={{ position: 'absolute', inset: 0 }}>
      {!isHomeActive ? (
        <div className="md-theme-animated">
          <div className={`md-theme-animated__background`}></div>
        </div>
      ) : (
        <AppThemeHue />
      )}
    </div>
  );
};

export default AppTheme;
