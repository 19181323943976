import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectThemeConfig } from '@core/store/appSlice/appSlice';
import { ThemeModeConstants } from '@helpers/themeHelper';

interface SliderProps {
  onChange: (value: number) => void;
  initialValue: number;
  width?: number;
}

type CanvasSize = [number, number];

const canvasSetup = (
  ctx: CanvasRenderingContext2D,
  intensity: number,
  colour: string,
  canvasSize: CanvasSize
) => {
  const dpi = window.devicePixelRatio;
  ctx.scale(dpi, dpi);
  canvasPaint(ctx, intensity, colour, canvasSize);
};

const canvasPaint = (
  ctx: CanvasRenderingContext2D,
  intensity: number,
  colour: string,
  canvasSize: CanvasSize
) => {
  ctx.clearRect(0, 0, canvasSize[0], canvasSize[1]);

  let storedValues = {
    strokeWidth: 1 + 3 * intensity,
    amplitude: 4 + 4 * intensity,
    frequency: 8 - 5 * intensity,
  };
  const padding = 4;
  const width = canvasSize[0] - padding;
  const height = canvasSize[1];

  ctx.beginPath();
  ctx.lineWidth = storedValues.strokeWidth;
  ctx.strokeStyle = colour;
  ctx.lineCap = 'round';
  ctx.lineJoin = 'round';

  let x = padding;
  let y = 0;
  const amplitude = storedValues.amplitude;
  const frequency = storedValues.frequency;
  while (x < width) {
    y = height / 2 + amplitude * Math.sin(x / frequency);
    ctx.lineTo(x, y);
    x = x + 1;
  }
  ctx.stroke();
};

const Slider: React.FC<SliderProps> = ({
  onChange,
  initialValue,
  width = 150,
}) => {
  const CSSBlock = 'nsc-slider';
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [value, setValue] = useState(initialValue);
  const [ctx, setCTX] = useState<CanvasRenderingContext2D | undefined>();
  const [canvasSize, setCanvasWidth] = useState<CanvasSize>([width, 30]);
  const dpi = window.devicePixelRatio;
  const themeMode = useAppSelector(selectThemeConfig).mode;
  const colour =
    themeMode === ThemeModeConstants.LIGHT
      ? 'rgba(0, 0, 0, 0.4)'
      : 'rgba(255, 255, 255, 0.6)';

  const drawCanvas = () => {
    if (ctx) canvasPaint(ctx, value, colour, canvasSize);
  };

  useEffect(() => {
    if (ctx) canvasSetup(ctx, value, colour, canvasSize);
    else if (canvasRef.current) setCTX(canvasRef.current.getContext('2d')!);
  }, [canvasRef, ctx]);

  useEffect(() => {
    onChange(value);
    drawCanvas();
  }, [value, themeMode]);

  return (
    <div
      className={`${CSSBlock}`}
      style={{ width: `${canvasSize[0] / 10}rem` }}>
      <div className={`${CSSBlock}__canvas-wrapper`}>
        <canvas
          ref={canvasRef}
          style={{ width: `${canvasSize[0]}px`, height: `${canvasSize[1]}px` }}
          width={canvasSize[0] * dpi}
          height={canvasSize[1] * dpi}></canvas>
      </div>
      <input
        type="range"
        min="0"
        max="1"
        step="0.1"
        defaultValue={value}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          // onChange(parseFloat(e.target.value))
          setValue(parseFloat(e.target.value))
        }
      />
    </div>
  );
};

export default Slider;
