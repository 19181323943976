import { File, FileMetadata, FileTypes, File_MD } from '@helpers/fileHelper';
import { JSONContent } from '@tiptap/core';

import { getFileStorageKey } from '../emdee/_common';
import { LSFiles } from '../localStorage/files';

/**
 *
 */
export default class LSFileMD {
  static updateContent(
    file: File | FileMetadata,
    content: JSONContent
  ): File_MD | undefined {
    const existingFile = LSFiles.load(file.uuid) as File_MD;
    if (existingFile) {
      const existingFile = LSFiles.load(file.uuid);
      const updatedFile: File = !!existingFile
        ? { ...existingFile, content: content }
        : { ...file, content };
      localStorage.setItem(
        getFileStorageKey(file.uuid),
        JSON.stringify(updatedFile)
      );
      return updatedFile;
    } else {
      console.error('LSFileMDX', 'updateContent', 'File not found!');
    }
  }
}
