import { useEffect, useRef, useState } from 'react';

export default function useComponentVisible(
  initialIsVisible: boolean,
  onBlurBegin?: () => Promise<any>
) {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const ref = useRef<any>(null);

  const onBlur = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onBlurBegin
        ? onBlurBegin().then(() => setIsVisible(false))
        : setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', onBlur, true);
    return () => document.removeEventListener('click', onBlur, true);
  }, []);

  return { ref, isVisible, setIsVisible };
}
