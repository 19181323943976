import { User } from 'firebase/auth';
import { arrayUnion, updateDoc } from 'firebase/firestore';

import { doc, getDoc, setDoc } from '@firebase/firestore';
import { File, Folder, getFileMetadata, isFolder } from '@helpers/fileHelper';
import { FSCollections } from '@providers/firestoreProvider';
import {
  emdeeFilesIndex,
  recursiveDeleteFileInIndex,
  recursiveRenameFileInIndex,
} from '@storage/emdee/_common';

import { db } from '../../firebase';
import { FSCollectionName } from './_types';

export default class FSIndex {
  /**
   *
   * @param user
   * @returns
   */
  static getIndexDoc = async (
    user: User
  ): Promise<emdeeFilesIndex | undefined> => {
    const indexRef = doc(FSCollections.Index, user.uid);
    const docSnap = await getDoc(indexRef);
    return docSnap.exists() ? (docSnap.data() as emdeeFilesIndex) : undefined;
  };

  /**
   *
   * @param user
   * @returns
   */
  static createIndexDoc = async (user: User): Promise<emdeeFilesIndex> => {
    const data = { content: [] };
    await setDoc(doc(db, FSCollectionName.INDEX, user.uid), data);
    return data;
  };

  /**
   *
   * @param user
   * @param file
   * @param folderUUID
   */
  static async addFile(user: User, file: File, folderUUID?: string) {
    const fileMetadata = getFileMetadata(file);
    const indexRef = doc(FSCollections.Index, user.uid);

    // Get current index
    let index = await FSIndex.getIndexDoc(user);
    if (!index) {
      console.info('FSIndex', 'addFile', 'No index found. Creating new index.');
      index = await FSIndex.createIndexDoc(user);
    }

    // Add to a folder
    if (folderUUID) {
      const folderIndex = index.content.findIndex(
        item => isFolder(item) && item.uuid === folderUUID
      );
      if (folderIndex > -1) {
        (index.content[folderIndex] as Folder).items.push(fileMetadata);
        await updateDoc(indexRef, { content: index.content });
      } else {
        console.error(
          'FSIndex',
          'addFile',
          `Couldn't find folder ${folderUUID}. Saving to root instead.`
        );
        await updateDoc(indexRef, { content: arrayUnion(fileMetadata) });
      }
    } else {
      // Add to root dir
      await updateDoc(indexRef, { content: arrayUnion(fileMetadata) });
    }
  }

  static async renameFile(user: User, file: File, newName: string) {
    const index = await FSIndex.getIndexDoc(user);
    if (index) {
      index.content = recursiveRenameFileInIndex(
        index.content,
        file.uuid,
        newName
      );
      await setDoc(doc(db, FSCollectionName.INDEX, user.uid), index);
    } else {
      console.error('FSIndex', 'renameFile', `Couldn't find index.`);
    }
  }

  static async deleteFile(user: User, file: File) {
    const index = await FSIndex.getIndexDoc(user);
    if (index) {
      index.content = recursiveDeleteFileInIndex(index.content, file.uuid);
      await setDoc(doc(db, FSCollectionName.INDEX, user.uid), index);
    } else {
      console.error('FSIndex', 'deleteFile', `Couldn't find index.`);
    }
  }

  static async moveFile(user: User, file: File, targetFolder: Folder) {
    const fileMetadata = getFileMetadata(file);
    const index = await FSIndex.getIndexDoc(user);
    if (index) {
      // Remove from current place
      index.content = recursiveDeleteFileInIndex(index.content, file.uuid);
      console.log(...index.content);

      // Add to new location
      if (targetFolder.uuid === '/') {
        index.content.push(fileMetadata);
      } else {
        let fileDirectory = index.content.find(
          item => item.uuid === targetFolder.uuid && isFolder(item)
        ) as Folder | undefined;
        if (fileDirectory) fileDirectory.items.push(fileMetadata);
      }

      // Commit doc
      await setDoc(doc(db, FSCollectionName.INDEX, user.uid), index);
    } else {
      console.error('FSIndex', 'moveFile', `Couldn't find index.`);
    }
  }

  /**
   *
   * @param user
   * @param folder
   */
  static async addFolder(user: User, folder: Folder) {
    const indexRef = doc(FSCollections.Index, user.uid);

    // Get current index
    let index = await FSIndex.getIndexDoc(user);
    if (!index) {
      console.info('FSIndex', 'addFile', 'No index found. Creating new index.');
      index = await FSIndex.createIndexDoc(user);
    }

    await updateDoc(indexRef, { content: arrayUnion(folder) });
  }
}
