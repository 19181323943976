import React, { useState } from 'react';

import ThemeConfigurator from '@components/settings/themeConfigurator/ThemeConfigurator';
import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import {
  selectOfflineMode,
  setOfflineMode,
} from '@core/store/userSlice/userSlice';
import NSCIconButton from '@nsc-ui/IconButton';
import Menu from '@nsc-ui/Menu/Menu';
import { MenuContent } from '@nsc-ui/Menu/MenuTypes';
import { useAuth } from '@providers/authProvider';

import AboutEmdee from './modals/AboutEmdee';
import Settings from './settings/Settings';

const menuItems: MenuContent = {
  sections: [
    {
      heading: 'Customisation',
      items: [
        {
          id: 'theme-config',
          faIcon: 'fa-paint-roller',
          text: 'Theme Configurator',
        },
        { id: 'settings', faIcon: 'fa-gear', text: 'Settings' },
      ],
    },
    {
      heading: 'About',
      items: [
        {
          id: 'about-emdee',
          faIcon: 'fa-circle-info',
          text: 'About emdee.io',
          // disabled: true,
        },
        {
          id: 'logout',
          faIcon: 'fa-arrow-right-from-bracket',
          text: 'Logout',
        },
      ],
      isDark: true,
    },
  ],
};

enum MODALS {
  THEME = 'theme-configurator',
  SETTINGS = 'settings',
  ABOUT = 'about',
}

const AppMenu: React.FC = () => {
  const CSSBlock = 'app-menu';
  const [activeModal, setActiveModal] = useState<undefined | MODALS>(undefined);
  const { user, logOut } = useAuth();
  const isOfflineMode = useAppSelector(selectOfflineMode);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setActiveModal(undefined);
  };

  const triggerEl = (
    <NSCIconButton
      className={`${CSSBlock}__trigger`}
      onClick={(e: React.MouseEvent) => {}}
      faIcon="fa-gear"
    />
  );

  const onMenuClick = (id: string) => {
    switch (id) {
      case 'theme-config':
        setActiveModal(MODALS.THEME);
        break;
      case 'settings':
        setActiveModal(MODALS.SETTINGS);
        break;
      case 'about-emdee':
        setActiveModal(MODALS.ABOUT);
        break;
      case 'logout':
        onLogout();
        break;
    }
  };

  const onLogout = async () => {
    if (isOfflineMode) dispatch(setOfflineMode(false));
    if (user)
      try {
        await logOut();
        // navigate('/');
      } catch (error) {
        console.log(error);
      }
  };

  return (
    <>
      <Menu triggerEl={triggerEl} onClick={onMenuClick} content={menuItems} />
      <ThemeConfigurator
        open={activeModal === MODALS.THEME}
        onClose={handleClose}
      />
      <Settings open={activeModal === MODALS.SETTINGS} onClose={handleClose} />
      <AboutEmdee open={activeModal === MODALS.ABOUT} onClose={handleClose} />
    </>
  );
};

export default AppMenu;
