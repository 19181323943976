import { User } from 'firebase/auth';

import emdee from '@core/emdee';
import { doc, getDoc, setDoc, updateDoc } from '@firebase/firestore';
import { ThemeConfig, defaultThemeConfig } from '@helpers/themeHelper';
import { initialDocContent } from '@helpers/userHelper';
import { FSCollections } from '@providers/firestoreProvider';

import { db } from '../../firebase';
import { FSCollectionName, FSCollection_User } from './_types';

export default class FSUser {
  /**
   *
   * @param user
   * @returns
   */
  static getUserDoc = async (
    user: User
  ): Promise<FSCollection_User | undefined> => {
    const userRef = doc(FSCollections.Users, user.uid);
    const docSnap = await getDoc(userRef);
    let data: FSCollection_User;

    if (docSnap.exists()) {
      console.log('Document data:', docSnap.data());
      data = docSnap.data() as FSCollection_User;
    } else {
      // doc.data() will be undefined in this case
      console.log(`No such document for uid: ${user.uid}`);
      return;
      // data = await FSUser.createUserDoc(user);
    }

    return data;
  };

  /**
   *
   * @param user
   * @returns
   */
  static createUserDoc = async (
    user: User,
    content?: FSCollection_User | ThemeConfig
  ): Promise<FSCollection_User> => {
    const data = initialDocContent(content);
    await setDoc(doc(db, FSCollectionName.USERS, user.uid), data);
    return data;
  };

  /**
   *
   * @param user
   * @param theme
   */
  static updateUserTheme = async (user: User, theme: ThemeConfig) => {
    const userRef = doc(FSCollections.Users, user.uid);
    await updateDoc(userRef, { theme: theme });
  };
}
