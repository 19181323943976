import React, { useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectActiveRequests } from '@core/store/appSlice/appSlice';

interface LoadingGlobalProps {
  isMounted: boolean;
}

const LoadingGlobal: React.FC<LoadingGlobalProps> = ({ isMounted }) => {
  const activeRequests = useAppSelector(selectActiveRequests);
  const CSSBlock = 'loading-global';
  const [hide, setHide] = useState(false);
  const mountedStyle = { animation: 'globalLoadingIn 100ms ease-in-out' };
  const unmountedStyle = { animation: 'globalLoadingOut 100ms ease-in-out' };

  return (
    <div
      className={`${CSSBlock} ${hide ? `${CSSBlock}--hide` : ''}`}
      style={isMounted ? mountedStyle : unmountedStyle}>
      <div className={`${CSSBlock}__loading-indicator`}></div>
      {/* {activeRequests.map((req, i) => (
        <div key={i}>{req}</div>
      ))} */}
    </div>
  );
};

export default LoadingGlobal;
