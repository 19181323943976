import { User } from 'firebase/auth';

import { ThunkDispatch } from '@reduxjs/toolkit';
import { FSCollection_User } from '@storage/firebase/_types';
import FSUser from '@storage/firebase/_users';
import LSUser from '@storage/localStorage/_users';

import { ThemeConfig, defaultThemeConfig, setTheme } from './themeHelper';

/**
 *
 * @returns
 */
export const initialDocContent = (
  content?: FSCollection_User | ThemeConfig
): FSCollection_User => {
  return {
    lastSeen: new Date(),
    theme: defaultThemeConfig,
    settings: {
      general: {
        enableExperimental: false,
        zenMode: false,
      },
      editor: {
        showToolbar: true,
        showMarkdownHints: true,
      },
      shortcuts: {},
    },
    ...(content ? content : {}),
  };
};

export const createUser = async ({
  dispatch,
  isOfflineMode,
  user,
  theme,
}: {
  dispatch: ThunkDispatch<any, any, any>;
  isOfflineMode: boolean;
  user?: User | null;
  theme?: ThemeConfig;
}) => {
  if (!isOfflineMode && !user) return;

  const settings = isOfflineMode
    ? LSUser.createUserDoc(theme)
    : await FSUser.createUserDoc(user!, theme);
  if (theme) {
    setTheme({ dispatch, isOfflineMode, user, theme });
  }
};
