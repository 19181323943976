import {
  CollectionReference,
  DocumentData,
  collection,
} from 'firebase/firestore';
import { ReactNode, createContext, useContext } from 'react';

import { File_MD, File_MDX } from '@helpers/fileHelper';
import { emdeeFilesIndex } from '@storage/emdee/_common';
import { FSCollection_User } from '@storage/firebase/_types';

import { db } from '../firebase';

export interface FirestoreProviderProps {
  children?: ReactNode;
}

export interface UserContextState {
  isAuthenticated: boolean;
  isLoading: boolean;
  id?: string;
}

export const UserStateContext = createContext<UserContextState>(
  {} as UserContextState
);

export const useUserContext = (): UserContextState => {
  return useContext(UserStateContext);
};

export interface FirestoreContextModel {
  // firestore: Firestore;
  usersCollection: CollectionReference<FSCollection_User>;
  indexCollection: CollectionReference<emdeeFilesIndex>;
  filesCollection: CollectionReference<File_MDX | File_MD>;
}

export const FirestoreContext = createContext<FirestoreContextModel>(
  {} as FirestoreContextModel
);

export function useFirestore(): FirestoreContextModel {
  return useContext(FirestoreContext);
}

export function createCollection<T = DocumentData>(collectionName: string) {
  return collection(db, collectionName) as CollectionReference<T>;
}

export const FSCollections = {
  Users: createCollection<FSCollection_User>('users'),
  Index: createCollection<emdeeFilesIndex>('index'),
  Files: createCollection<File_MD | File_MDX>('files'),
};

export const FirestoreProvider = ({
  children,
}: FirestoreProviderProps): JSX.Element => {
  const values = {
    usersCollection: FSCollections.Users,
    indexCollection: FSCollections.Index,
    filesCollection: FSCollections.Files,
  };

  return (
    <FirestoreContext.Provider value={values}>
      {children}
    </FirestoreContext.Provider>
  );
};
