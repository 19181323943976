import { User } from 'firebase/auth';
import { updateDoc } from 'firebase/firestore';

import {
  EmdeeSettings,
  setShowMarkdownHints,
  setShowToolbar,
  setZenMode,
} from '@core/store/settingsSlice/settingsSlice';
import { doc, getDoc } from '@firebase/firestore';
import { FSCollections } from '@providers/firestoreProvider';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { FSCollection_User } from '@storage/firebase/_types';

export const LSSettingsKey = `emdee.iosettings`;

const _getSettingsLS = (): EmdeeSettings | undefined => {
  try {
    const settings = localStorage.getItem(LSSettingsKey);
    return settings ? (JSON.parse(settings) as EmdeeSettings) : undefined;
  } catch (e: any) {
    console.error('settingsHelper', '_getSettingsLS', e);
    return undefined;
  }
};

const _getSettingsFS = async (
  user: User
): Promise<EmdeeSettings | undefined> => {
  const fileRef = doc(FSCollections.Users, user.uid);
  const docSnap = await getDoc(fileRef);
  return docSnap.exists()
    ? (docSnap.data() as FSCollection_User).settings
    : (console.error('File not found!', user.uid), undefined);
};

/**
 * Get app settings
 * @param isOfflineMode
 * @param user
 */
export const getSettings = async (
  isOfflineMode: boolean,
  user?: User | null
): Promise<EmdeeSettings | undefined> => {
  console.log('getSettings');
  if (!isOfflineMode && !user) return;

  const settings = isOfflineMode
    ? _getSettingsLS()
    : await _getSettingsFS(user!);
  return settings;
};

/*
    Setters
*/
const _setSettingsLS = (settings: EmdeeSettings) => {
  try {
    localStorage.setItem(LSSettingsKey, JSON.stringify(settings));
  } catch (e: any) {
    console.error('settingsHelper', '_setSettingsLS', e);
    return undefined;
  }
};
const _setSettingsFS = async (settings: EmdeeSettings, user: User) => {
  const userRef = doc(FSCollections.Users, user.uid);
  const docSnap = await getDoc(userRef);
  if (docSnap) {
    await updateDoc(userRef, { settings: settings });
  }
};

/**
 *
 * @param dispatch
 * @param settings
 */
export const setEmdeeSettings = async ({
  dispatch,
  settings,
  isOfflineMode,
  user,
}: {
  dispatch: ThunkDispatch<any, any, any>;
  settings: EmdeeSettings;
  isOfflineMode: boolean;
  user?: User | null;
}) => {
  // Save in store
  dispatch(setZenMode(settings.general.zenMode));
  dispatch(setShowToolbar(settings.editor.showToolbar));
  dispatch(setShowMarkdownHints(settings.editor.showMarkdownHints));

  // Save in user
  if (!isOfflineMode && !user) return;
  return isOfflineMode
    ? _setSettingsLS(settings)
    : await _setSettingsFS(settings, user!);
};
