import React, { useEffect, useState } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectEditor } from '@core/store/pageSlice/pageSlice';

interface ToolbarButtonProps {
  icon: string;
  onClick: () => void;
}

const ToolbarButton: React.FC<ToolbarButtonProps> = ({ icon, onClick }) => {
  const CSSBlock = 'toolbar-button';
  const editor = useAppSelector(selectEditor);

  return (
    <button className={`${CSSBlock}`} onClick={onClick}>
      <i className={icon}></i>
    </button>
  );
};

export default ToolbarButton;
