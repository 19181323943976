import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@core/hooks/appHooks';
import {
  selectThemeConfig,
  setThemeConfig,
} from '@core/store/appSlice/appSlice';
import { selectOfflineMode } from '@core/store/userSlice/userSlice';
import {
  ThemeConfig,
  ThemeMode,
  ThemeModeConstants,
} from '@helpers/themeHelper';

const setAppTheme = (isDarkMode: boolean) =>
  document.body.classList.toggle(`theme-dark`, isDarkMode);

const ThemeSwitcher: React.FC = () => {
  const CSSBlock = 'theme-switcher';
  const dispatch = useAppDispatch();
  const themeConfig = useAppSelector(selectThemeConfig);
  const isOfflineMode = useAppSelector(selectOfflineMode);
  const [themeState, setThemeState] = useState(themeConfig.mode);
  const [modifierClass, setModifierClass] = useState('system');

  useEffect(() => {
    setModifierClass(
      themeState === ThemeModeConstants.LIGHT
        ? 'light'
        : themeState === ThemeModeConstants.DARK
        ? 'dark'
        : 'system'
    );

    let isDarkMode = themeState === ThemeModeConstants.DARK;
    const changeHandler = (e: MediaQueryListEvent) => setAppTheme(!!e.matches);
    const mq = window.matchMedia('(prefers-color-scheme: dark)');

    if (themeState === ThemeModeConstants.SYSTEM) {
      mq.addEventListener('change', changeHandler);
      isDarkMode = mq.matches;
    }

    setAppTheme(
      themeState === ThemeModeConstants.SYSTEM
        ? mq.matches
        : themeState === ThemeModeConstants.DARK
    );

    return () => mq.removeEventListener('change', changeHandler);
  }, [themeState]);

  const onClick = (type: ThemeMode) => {
    setThemeState(type);

    const updatedTheme: ThemeConfig = {
      ...themeConfig,
      mode: type,
    };
    dispatch(setThemeConfig(updatedTheme));
  };

  return (
    <div className={`${CSSBlock} ${CSSBlock}--${modifierClass}`}>
      <div
        className={`${CSSBlock}__item ${
          modifierClass === 'system' ? `${CSSBlock}--active` : ``
        }`}
        onClick={() => onClick(ThemeModeConstants.SYSTEM)}>
        <div className={`${CSSBlock}__item-icon`}>
          <i className="fa-solid fa-desktop"></i>
        </div>
        <div className={`${CSSBlock}__item-text`}>System</div>
      </div>
      <div
        className={`${CSSBlock}__item ${
          modifierClass === 'light' ? `${CSSBlock}--active` : ``
        }`}
        onClick={() => onClick(ThemeModeConstants.LIGHT)}>
        <div className={`${CSSBlock}__item-icon`}>
          <i className="fa-solid fa-sun"></i>
        </div>
        <div className={`${CSSBlock}__item-text`}>Light</div>
      </div>
      <div
        className={`${CSSBlock}__item ${
          modifierClass === 'dark' ? `${CSSBlock}--active` : ``
        }`}
        onClick={() => onClick(ThemeModeConstants.DARK)}>
        <div className={`${CSSBlock}__item-icon`}>
          <i className="fa-solid fa-moon"></i>
        </div>
        <div className={`${CSSBlock}__item-text`}>Dark</div>
      </div>
      <div className={`${CSSBlock}__indicator`}></div>
    </div>
  );
};

export default ThemeSwitcher;
