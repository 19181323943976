import { Application, Sprite, Texture } from 'pixi.js';
import React, { useEffect } from 'react';

import { ThemeConfig, isDarkMode } from '@helpers/themeHelper';

const size = [500, 500];
const easeInOutSine = (x: number) => -(Math.cos(Math.PI * x) - 1) / 2;
const createGradTexture = (hsl: string, posY: number) => {
  const canvas = document.createElement('canvas');
  canvas.width = size[0];
  canvas.height = size[1];
  const ctx = canvas.getContext('2d');
  if (!ctx) return;

  const posX = size[0] / 2;
  const gradient = ctx.createRadialGradient(
    posX,
    posY,
    30,
    posX,
    posY,
    size[0] * (2 / 3)
  );

  gradient.addColorStop(0, `hsl(${hsl})`);
  gradient.addColorStop(1, `hsla(${hsl}, 0)`);
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, size[0], size[1]);
  return Texture.from(canvas);
};

const getHSLPrimary = (isDarkMode: boolean, hue: number, intensity: number) =>
  `${hue}, ${isDarkMode ? 10 + 40 * intensity : 20 + 80 * intensity}%, ${
    isDarkMode ? 30 + 6 * intensity : 92 - 16 * intensity
  }%`;
const getHSLSecondary = (isDarkMode: boolean, hue: number, intensity: number) =>
  `${hue}, ${isDarkMode ? 10 + 40 * intensity : 20 + 80 * intensity}%, ${
    isDarkMode ? 30 + 4 * intensity : 88 - 12 * intensity
  }%`;

const draw = (themeConfig: ThemeConfig, hueRotate: boolean) => {
  const app = new Application({
    antialias: true,
    backgroundAlpha: 0,
    width: size[0],
    height: size[1],
  });

  const themeNode = document.getElementById('md-theme');
  if (!themeNode) return;

  const canvasNode = themeNode.appendChild(app.view as HTMLCanvasElement);
  canvasNode.id = 'md-theme-hue';

  const spritePrimary = new Sprite(
    createGradTexture(
      getHSLPrimary(
        isDarkMode(themeConfig.mode),
        themeConfig.hue[0],
        themeConfig.intensity
      ),
      0
    )
  );
  spritePrimary.position.set(0, 0);
  spritePrimary.width = size[0] * 2;
  spritePrimary.height = size[1] * 2;
  app.stage.addChild(spritePrimary);

  const spriteSecondary = new Sprite(
    createGradTexture(
      getHSLSecondary(
        isDarkMode(themeConfig.mode),
        themeConfig.hue[1],
        themeConfig.intensity
      ),
      size[1] / 2
    )
  );
  spriteSecondary.position.set(0, 0);
  spriteSecondary.width = size[0] * 2;
  spriteSecondary.height = size[1] * 2;
  app.stage.addChild(spriteSecondary);

  let elapsed = 0.0;
  app.ticker.add(delta => {
    elapsed += delta / 200;
    spritePrimary.x = -size[0] + size[0] * easeInOutSine(elapsed);
    spriteSecondary.x = -size[0] * easeInOutSine(elapsed);
  });

  return () => {
    canvasNode.remove();
  };
};

interface AppThemeHueContentsProps {
  themeConfig: ThemeConfig;
  hueRotate?: boolean;
}

const AppThemeHueContents: React.FC<AppThemeHueContentsProps> = ({
  themeConfig,
  hueRotate,
}) => {
  useEffect(() => {
    const drawTheme = draw(themeConfig, !!hueRotate);
    return drawTheme;
  }, [themeConfig, hueRotate]);

  return <></>;
};

export default AppThemeHueContents;
