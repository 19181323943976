import React, { CSSProperties } from 'react';

import { useAppSelector } from '@core/hooks/appHooks';
import { selectThemeConfig } from '@core/store/appSlice/appSlice';
import { CSSVARS, ThemeModeConstants } from '@helpers/themeHelper';

interface HueButtonProps {
  hueVariable: CSSVARS;
  text: string;
  active: boolean;
  onClick: () => void;
}

const HueButton: React.FC<HueButtonProps> = ({
  hueVariable,
  text,
  onClick,
  active,
}) => {
  const CSSBlock = 'hue-button';
  const themeMode = useAppSelector(selectThemeConfig).mode;
  const hsl = `hsla(var(${hueVariable}), 70%, 80%, 100%)`;
  // const hslBackground = `hsla(var(${hueVariable}), 40%, 80%, 100%)`;
  const hslBackground =
    themeMode === ThemeModeConstants.LIGHT
      ? `hsla(var(${hueVariable}), 40%, 70%, 100%)`
      : `hsla(var(${hueVariable}), 40%, 50%, 100%)`;

  return (
    <button
      style={
        {
          '--button-hue': hueVariable.toString(),
          background: hslBackground,
          boxShadow: `inset 0 0 0 0.3rem ${hsl}`,
        } as CSSProperties
      }
      className={`${CSSBlock} ${active ? `${CSSBlock}--active` : ''}`}
      onClick={onClick}>
      {/* <span
        className={`${CSSBlock}__colour`}
        style={{ background: hslBackground, borderColor: hsl }}></span> */}
      <span className={`${CSSBlock}__text`} style={{}}>
        {text}
      </span>
    </button>
  );
};

export default HueButton;
