import React from 'react';

import { useAppSelector } from '@core/hooks/appHooks';

import { selectThemeConfig } from '../store/appSlice/appSlice';
import AppThemeHueContents from './AppThemeHueContents';

const AppThemeHue: React.FC = () => {
  const themeConfig = useAppSelector(selectThemeConfig);
  return <AppThemeHueContents themeConfig={themeConfig} />;
};

export default AppThemeHue;
